import React from 'react';
import { connect } from 'react-redux';
import events_store from '../../store/events';
import app from '../../store/app';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../utils/Translator';
import events from '../../utils/Events';

class NotificationSettings extends React.PureComponent {

    clearNotifications() {
        this.props.clear();
        this.toggleSounds=this.toggleSounds.bind(this);
        this.toggleDisplay=this.toggleDisplay.bind(this);
        events.emit(events_store.types.CLEAR);
    }

    toggleSounds(e) {
        const newSettings = Object.assign({}, this.props.settings);
        newSettings.soundEnabled = !newSettings.soundEnabled;
        this.props.changeNotificationsSettings(newSettings);
        events.emit(app.types.SET_NOTIFICATIONS_SETTINGS, newSettings);
    }

    toggleDisplay(e) {
        const newSettings = Object.assign({}, this.props.settings);
        newSettings.enabled = !newSettings.enabled;
        this.props.changeNotificationsSettings(newSettings);
        events.emit(app.types.SET_NOTIFICATIONS_SETTINGS, newSettings);
    }

    render() {
        const { soundEnabled, enabled } = this.props.settings;
        return (
            <DropdownButton
                variant="light"
                size="sm"
                className="shadow-none md-margin"
                title={<i className="mdi mdi-settings"></i>}

            >
                <Dropdown.Item key={1} eventKey={1} onClick={(e) => this.toggleDisplay(e)}>
                    <label className="custom-chk">
                        <input type="checkbox" checked={enabled}  readOnly />
                        <span className="checkmark" onClick={(e) => null}></span>
                        &nbsp;<i className="mdi mdi-bell-ring-outline"></i>&nbsp;{t('sharedNotifications')}
                    </label>

                </Dropdown.Item>
                <Dropdown.Item key={2} eventKey={2} onClick={(e) => this.toggleSounds(e)}>
                    <label className="custom-chk">
                        <input type="checkbox" checked={soundEnabled} readOnly />
                        <span className="checkmark" onClick={(e) => null}></span>
                        &nbsp;<i className="mdi mdi-alarm-bell"></i>&nbsp;{t('sharedSound')}
                    </label>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item key={3} eventKey={3} onClick={(e) => this.clearNotifications(e)}>
                    <i className="mdi mdi-delete-sweep"></i>&nbsp;{t('reportClear')}
                </Dropdown.Item>
            </DropdownButton>
        )
    }
}

const mapStateToProps = (state) => ({
    settings: state.app.notificationstate
})

const mapDispatchToProps = (dispatch) => ({
    changeNotificationsSettings: (settings) => dispatch(app.actions.setNotificationsSettings(settings)),
    clear: () => dispatch(events_store.actions.clear())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings)