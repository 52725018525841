import React from "react";
import AttributesPage from "../Attributes";
import {Form, FormControl, FormGroup, FormLabel, InputGroup} from "react-bootstrap";
import {Button} from '@mui/material'
import {t} from "../../../utils/Translator";
import {Formik} from "formik";
import * as yup from "yup";
import {User} from "../../../models";
import {deepClone, isEqual} from "../../../utils/functions";
import CustomSelect from "../../Controls/CustomSelect";
import CustomCheckbox from "../../Controls/CustomCheckBox";
import {store} from "../../../store";
import constants from "../../../config/constants";
import Collapse from "../../Collapse";
import Datetime from "react-datetime";
import PermissionsToolbar from "../Permissions/toolbar";
import {App} from "../../../App";
import CustomNumberField from "../../Controls/CustomNumberField";
import {Grid} from "@mui/material";
import {IconButton} from "@mui/material";
import TextField from '@mui/material/TextField';

export default class UserForm extends React.Component {
    constructor(props) {
        super(props);

        const mapOptions = [];
        Object.keys(constants.mapTypes[constants.selectedMapType].mapServices).map(
            i => {
                const c = constants.mapTypes[constants.selectedMapType].mapServices[i];
                if (c.enabled) {
                    mapOptions.push({label: t(c.name), value: i, config: c});
                }
            }
        );

        const coordinateFormats = [];
        const coordinate_formats = store.getState().coordinate_formats
            .coordinate_formats;
        Object.values(coordinate_formats).map(o => {
            coordinateFormats.push({label: t(o.name), value: o.key});
        });

        const item = new User().deserialize(deepClone(props.item))
        if (item.attributes === undefined)
            item.attributes = {}

        this.state = {
            attributesPage: false,
            item: item,
            mode: props.mode,
            sending: false,
            changed: false,
            mapOptions: mapOptions,
            canAddUser: item.id?item.userLimit===-1:false,
            coordinateFormats: coordinateFormats
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
    }

    save(values) {
        const item = this.state.item.deserialize(values);
        item.userLimit = this.state.canAddUser ? -1 : 0
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true});
    }

    onAttributesCancel() {
        const clone = new User().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        });
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        });
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({item: new User().deserialize(deepClone(newProps.item))});
    }

    getMapState(setFieldValue) {
        const state = store.getState().app.mapstate;
        setFieldValue("zoom", state.zoom);
        setFieldValue("latitude", state.latitude);
        setFieldValue("longitude", state.longitude);
    }

    render() {
        const {sending, coordinateFormats, mapOptions, item, canAddUser} = this.state;

        const schema = yup.object({
            name: yup
                .string()
                .min(4)
                .required(),
            email: yup
                .string()
                .email()
                .required(),
            password: yup.string().min(4),
            mapLayer: yup.string(),
            latitude: yup.number(),
            longitude: yup.number(),
            zoom: yup
                .number()
                .min(1)
                .optional(),
            twelveHourFormat: yup.bool(),
            coordinateFormat: yup.string(),
            poiLayer: yup.string().optional()
        });

        return (
            <React.Fragment>
                {this.state.attributesPage ? (
                    <AttributesPage
                        item={item}
                        type="usersAttributes"
                        onHide={() => this.onAttributesClosed()}
                        onSave={() => this.onAttributesClosed()}
                        onCancel={() => this.onAttributesCancel()}
                    />
                ) : null}

                <div className="container-wrapper">
                    <Formik
                        validationSchema={schema}
                        onSubmit={this.save}
                        disabled={sending}
                        initialValues={{
                            name: item.name,
                            email: item.email,
                            password: "",
                            phone: item.phone,
                            mapLayer: item.map || mapOptions[0].value,
                            latitude: item.latitude || constants.mapDefaultLat,
                            longitude: item.longitude || constants.mapDefaultLon,
                            zoom: item.zoom || constants.mapDefaultZoom,
                            twelveHourFormat: item.twelveHourFormat
                                ? item.twelveHourFormat
                                : false,
                            coordinateFormat:
                                item.coordinateFormat || coordinateFormats[0].value,
                            poiLayer: item.poiLayer || "",
                            disabled: item.disabled,
                            administrator: item.administrator,
                            readonly: item.readonly,
                            deviceReadonly: item.deviceReadonly,
                            limitCommands: item.limitCommands,
                            expiration: item.expirationTime,
                            deviceLimit: item.deviceLimit ? item.deviceLimit : -1,
                            userLimit: item.userLimit ? item.userLimit : 0,
                            attributes: item.attributes ? item.attributes : {},
                            canAddUser,
                            deactive_attributes: item.attributes && item.attributes['deactive_attributes'] ? item.attributes['deactive_attributes'] : null,
                            deactive_calendars: item.attributes && item.attributes['deactive_calendars'] ? item.attributes['deactive_calendars'] : null,
                            deactive_drivers: item.attributes && item.attributes['deactive_drivers'] ? item.attributes['deactive_drivers'] : null,
                            deactive_geofences: item.attributes && item.attributes['deactive_geofences'] ? item.attributes['deactive_geofences'] : null,
                            deactive_groups: item.attributes && item.attributes['deactive_groups'] ? item.attributes['deactive_groups'] : null,
                            deactive_maintenance: item.attributes && item.attributes['deactive_maintenance'] ? item.attributes['deactive_maintenance'] : null,
                            activate_customer_devices: item.attributes && item.attributes['activate_customer_devices'] ? item.attributes['activate_customer_devices'] : null,
                            delete_devices_disabled: item.attributes && item.attributes['delete_devices_disabled'] ? item.attributes['delete_devices_disabled'] : null,
                            settings_owner_notifications: item.attributes && item.attributes['settings_owner_notifications'] ? item.attributes['settings_owner_notifications'] : null
                        }}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              dirty,
                              isSubmitting,
                              errors,
                              isValid,
                              setFieldValue
                          }) => (
                            <Form
                                onSubmit={handleSubmit}
                                noValidate
                                className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm "
                            >
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container">
                                            <h3 className="internal-title">
                                                <i className="mdi mdi mdi-account-multiple"></i>{" "}
                                                {this.state.item.id ? t("sharedEdit") : t("sharedAdd")}{" "}
                                                {t("settingsUsers")}
                                            </h3>
                                            {this.state.item.id ? (
                                                <PermissionsToolbar item={this.state.item}/>
                                            ) : null}

                                            <Grid container rowSpacing={1}
                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("sharedName")}</FormLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="name"
                                                            defaultValue={values.name}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.name}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("userEmail")}</FormLabel>
                                                        <FormControl
                                                            name="email"
                                                            defaultValue={values.email}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.email}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("sharedPhone")}</FormLabel>
                                                        <FormControl
                                                            name="phone"
                                                            defaultValue={values.phone}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.phone}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("userPassword")}</FormLabel>
                                                        <FormControl
                                                            name="password"
                                                            defaultValue={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>

                                            <hr/>

                                            <Grid className="mt-4" container rowSpacing={1}
                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                <Grid item xs={6}>
                                                    <FormGroup style={{zIndex: 1}}>
                                                        <FormLabel>{t("mapLayer")}</FormLabel>
                                                        <CustomSelect
                                                            options={mapOptions}
                                                            onChange={setFieldValue}
                                                            defaultValue={values.mapLayer}
                                                            name="map"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("serverZoom")}</FormLabel>
                                                        <InputGroup>
                                                            <FormControl
                                                                type="number"
                                                                name="zoom"
                                                                min="1"
                                                                className="d-inline-block"
                                                                value={values.zoom}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.zoom}
                                                            />
                                                            <InputGroup.Append className="mb-0 mt-0"
                                                                               style={{
                                                                                   height: "2rem"
                                                                               }}
                                                            >
                                                                <IconButton
                                                                    style={{
                                                                        border: "none",
                                                                        outline: "none"
                                                                    }}
                                                                    color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0"
                                                                    onClick={() => this.getMapState(setFieldValue)}
                                                                    title={t("sharedGetMapState")}
                                                                >
                                                                    <i className="mdi mdi-map-marker"></i>
                                                                </IconButton>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("positionLatitude")}</FormLabel>
                                                        <FormControl
                                                            type="number"
                                                            name="latitude"
                                                            value={values.latitude}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.latitude}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("positionLongitude")}</FormLabel>
                                                        <FormControl
                                                            type="number"
                                                            name="longitude"
                                                            value={values.longitude}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.longitude}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("settingsCoordinateFormat")}</FormLabel>
                                                        <CustomSelect
                                                            options={coordinateFormats}
                                                            onChange={setFieldValue}
                                                            defaultValue={values.coordinateFormat}
                                                            name="coordinateFormat"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("mapPoiLayer")}</FormLabel>
                                                        <FormControl
                                                            type="string"
                                                            name="poiLayer"
                                                            defaultValue={values.poiLayer}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.poiLayer}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <CustomCheckbox
                                                            name="twelveHourFormat"
                                                            defaultValue="twelveHourFormat"
                                                            checked={values.twelveHourFormat}
                                                            onChange={handleChange}
                                                            label={t("settingsTwelveHourFormat")}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>

                                            {App.App.user.administrator ?
                                                <FormGroup>
                                                    <TextField
                                                        className="w-100"
                                                        id="outlined-textarea"
                                                        InputProps={{style: {fontSize: 14}}}
                                                        InputLabelProps={{style: {fontSize: 14}}}
                                                        label={t("Contact Info")}
                                                        placeholder={t("User's contact info")}
                                                        value={values.attributes['owner_contact_info']}
                                                        onChange={
                                                            event => {
                                                                const user = this.state.item
                                                                user.attributes["owner_contact_info"] = event.target.value
                                                                this.setState({item: user, changed: true})
                                                                handleChange(event)
                                                            }
                                                        }
                                                        multiline
                                                    />
                                                </FormGroup>
                                                : null
                                            }

                                            <hr/>

                                            <Grid className="mt-4" container rowSpacing={1}
                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("settingsDistanceUnit")}</FormLabel>
                                                        <FormControl
                                                            as="select"
                                                            name="value"
                                                            value={values.attributes["distanceUnit"]}
                                                            onChange={event => {
                                                                this.state.item.attributes["distanceUnit"] = event.target.value
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {Object.values(store.getState().distance_units.distance_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                                return <option key={o.key}
                                                                               value={o.key}>{t(o.key)}</option>
                                                            })}
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("settingsSpeedUnit")}</FormLabel>
                                                        <FormControl
                                                            as="select"
                                                            name="value"
                                                            value={values.attributes["speedUnit"]}
                                                            onChange={event => {
                                                                this.state.item.attributes["speedUnit"] = event.target.value
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {Object.values(store.getState().speed_units.speed_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                                return <option key={o.key}
                                                                               value={o.key}>{t(o.key)}</option>
                                                            })}
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("sharedTimezone")}</FormLabel>
                                                        <FormControl
                                                            as="select"
                                                            name="timezone"
                                                            defaultValue={values.attributes["timezone"]}
                                                            onChange={event => {
                                                                this.state.item.attributes["timezone"] = event.target.value
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }
                                                            }>
                                                            <option value={""}></option>
                                                            {Object.values(store.getState().timezones.timezones).sort((a, b) => a.key > b.key ? 1 : -1).map(o => {
                                                                return <option key={o.key}
                                                                               value={o.key}>{t(o.key)}</option>
                                                            })}
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("settingsVolumeUnit")}</FormLabel>
                                                        <FormControl
                                                            as="select"
                                                            name="value"
                                                            value={values.attributes["volumeUnit"]}
                                                            onChange={event => {
                                                                this.state.item.attributes["volumeUnit"] = event.target.value
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {Object.values(store.getState().volume_units.volume_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                                return <option key={o.key}
                                                                               value={o.key}>{t(o.key)}</option>
                                                            })}
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>

                                                {App.App.user.administrator ?
                                                    <Grid item xs={6}>
                                                        <FormGroup>
                                                            <FormLabel>{t("attributeWebLiveRouteLength")}</FormLabel>
                                                            <CustomNumberField
                                                                name="value"
                                                                value={values.attributes["web.liveRouteLength"]}
                                                                type="number"
                                                                onChange={event => {
                                                                    if (event.target.value > 0)
                                                                        this.state.item.attributes["web.liveRouteLength"] = event.target.value
                                                                    else
                                                                        delete this.state.item.attributes["web.liveRouteLength"]
                                                                    handleChange(event)
                                                                    this.setState({changed: true})
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Grid> : null}

                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("attributeWebMaxZoom")}</FormLabel>
                                                        <CustomNumberField
                                                            name="maxZoom"
                                                            value={values.attributes["web.maxZoom"]}
                                                            type="number"
                                                            onChange={event => {
                                                                if (event.target.value > 0)
                                                                    this.state.item.attributes["web.maxZoom"] = event.target.value
                                                                else
                                                                    delete this.state.item.attributes["web.maxZoom"]
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormGroup>
                                                        <FormLabel>{t("attributeWebSelectZoom")}</FormLabel>
                                                        <CustomNumberField
                                                            name="selectZoom"
                                                            value={values.attributes["web.selectZoom"]}
                                                            type="number"
                                                            onChange={event => {
                                                                if (event.target.value > 0)
                                                                    this.state.item.attributes["web.selectZoom"] = event.target.value
                                                                else
                                                                    delete this.state.item.attributes["web.selectZoom"]
                                                                handleChange(event)
                                                                this.setState({changed: true})
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>

                                            {App.App.user.id !== this.state.item.id ||
                                            App.App.user.is_super_admin === 1 ? (
                                                <div className="mt-3">
                                                    <Collapse
                                                        title={
                                                            <h5 className="m-0">{t("sharedPermissions")}</h5>
                                                        }
                                                    >
                                                        <div className="clearfix">
                                                            <Grid container rowSpacing={1}
                                                                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <FormLabel>
                                                                            {t("userExpirationTime")}
                                                                        </FormLabel>
                                                                        <Datetime
                                                                            timeFormat={false}
                                                                            dateFormat="YYYY-MM-DD"
                                                                            name="expiration"
                                                                            defaultValue={this.state.item.id ? values.expiration : ""}
                                                                            onChange={handleChange}
                                                                        >
                                                                            {" "}
                                                                        </Datetime>
                                                                    </FormGroup>
                                                                </Grid>

                                                                {/*todo deviceLimit*/}
                                                                {/*{App.App.user.administrator ? (*/}
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <FormLabel>
                                                                            {t("userDeviceLimit")}
                                                                        </FormLabel>
                                                                        <FormControl
                                                                            type="number"
                                                                            name="deviceLimit"
                                                                            defaultValue={values.deviceLimit}
                                                                            onChange={handleChange}
                                                                            isInvalid={!!errors.deviceLimit}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                {/*) : null}*/}

                                                                    <Grid item xs={6}>
                                                                         <FormGroup>
                                                                             <CustomCheckbox
                                                                                 name="userLimit"
                                                                                 defaultChecked={values.canAddUser}
                                                                                 onChange={event => {
                                                                                   this.setState({changed: true, canAddUser: event.target.checked })
                                                                                 }}
                                                                                 label={t("Can add users")}
                                                                             />
                                                                         </FormGroup>
                                                                    </Grid>

                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="administrator"
                                                                                defaultValue="administrator"
                                                                                defaultChecked={values.administrator}
                                                                                onChange={handleChange}
                                                                                label={t("userAdmin")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>


                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="disabled"
                                                                                defaultValue="disabled"
                                                                                defaultChecked={values.disabled}
                                                                                onChange={handleChange}
                                                                                label={t("sharedDisabled")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>

                                                                {App.App.user.administrator && App.App.user.is_super_admin === 1 ? (
                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="administrator"
                                                                                defaultValue="administrator"
                                                                                defaultChecked={values.administrator}
                                                                                onChange={handleChange}
                                                                                label={t("userAdmin")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                ) : null}

                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="readonly"
                                                                            defaultValue="readonly"
                                                                            defaultChecked={values.readonly}
                                                                            onChange={handleChange}
                                                                            label={t("serverReadonly")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="delete_devices_disabled"
                                                                            defaultValue="delete_devices_disabled"
                                                                            defaultChecked={values.delete_devices_disabled}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["delete_devices_disabled"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={t("Prevent device deletion")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="deactive_attributes"
                                                                                defaultValue="deactive_attributes"
                                                                                defaultChecked={values.deactive_attributes}
                                                                                onChange={event => {
                                                                                    this.state.item.attributes["deactive_attributes"] = event.target.checked
                                                                                    handleChange(event)
                                                                                    this.setState({changed: true})
                                                                                }}
                                                                                label={t("attributeUiDisableComputedAttributes")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deactive_calendars"
                                                                            defaultValue="deactive_calendars"
                                                                            defaultChecked={values.deactive_calendars}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["deactive_calendars"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={t("attributeUiDisableCalendars")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deviceReadonly"
                                                                            defaultValue="deviceReadonly"
                                                                            defaultChecked={values.deviceReadonly}
                                                                            onChange={handleChange}
                                                                            label={t("userDeviceReadonly")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deactive_drivers"
                                                                            defaultValue="deactive_drivers"
                                                                            defaultChecked={values.deactive_drivers}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["deactive_drivers"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={t("attributeUiDisableDrivers")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deactive_geofences"
                                                                            defaultValue="deactive_geofences"
                                                                            defaultChecked={values.deactive_geofences}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["deactive_geofences"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={
                                                                                t("UI:Disable") +
                                                                                " " +
                                                                                t("sharedGeofence")
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deactive_groups"
                                                                            defaultValue="deactive_groups"
                                                                            defaultChecked={values.deactive_groups}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["deactive_groups"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={
                                                                                t("UI:Disable") + " " + t("groupParent")
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <CustomCheckbox
                                                                            name="deactive_maintenance"
                                                                            defaultValue="deactive_maintenance"
                                                                            defaultChecked={values.deactive_maintenance}
                                                                            onChange={event => {
                                                                                this.state.item.attributes["deactive_maintenance"] = event.target.checked
                                                                                handleChange(event)
                                                                                this.setState({changed: true})
                                                                            }}
                                                                            label={t("attributeUiDisableMaintenance")}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>

                                                                {App.App.user.administrator ?
                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="limitCommands"
                                                                                defaultValue="limitCommands"
                                                                                defaultChecked={values.limitCommands}
                                                                                onChange={handleChange}
                                                                                label={t("userLimitCommands")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    : null}

                                                                {App.App.user.administrator ?
                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="activate_customer_devices"
                                                                                defaultValue="activate_customer_devices"
                                                                                defaultChecked={values.activate_customer_devices}
                                                                                onChange={event => {
                                                                                    this.state.item.attributes["activate_customer_devices"] = event.target.checked
                                                                                    handleChange(event)
                                                                                    this.setState({changed: true})
                                                                                }}
                                                                                label={t("activateCustomerDevices")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    : null}

                                                                {App.App.user.administrator ?
                                                                    <Grid item xs={6}>
                                                                        <FormGroup>
                                                                            <CustomCheckbox
                                                                                name="settings_owner_notifications"
                                                                                defaultValue="settings_owner_notifications"
                                                                                defaultChecked={values.settings_owner_notifications}
                                                                                onChange={event => {
                                                                                    this.state.item.attributes["settings_owner_notifications"] = event.target.checked
                                                                                    handleChange(event)
                                                                                    this.setState({changed: true})
                                                                                }}
                                                                                label={t("Enable expire notification settings")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    : null}
                                                            </Grid>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {App.App.user.administrator ?
                                                    <Button
                                                        variant="primary"
                                                        className="align-self-stretch"
                                                        onClick={() => this.openAttributesPage()}
                                                    >
                                                        <i className="mdi mdi-playlist-play"></i>{" "}
                                                        {t("sharedAttributes")}
                                                    </Button> : null
                                                }
                                            </div>
                                            <Button
                                                variant="contained" size="large"
                                                className="btn-red mr-2"
                                                onClick={() => this.props.onHide()}
                                            >
                                                <i className="mdi mdi-close"></i> {t("sharedCancel")}
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained" size="large" className="btn-blue"
                                                disabled={
                                                    !this.state.changed &&
                                                    (!dirty || !isValid)
                                                }
                                            >
                                                <i className="mdi mdi-content-save"></i>{" "}
                                                {t("sharedSave")}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        );
    }
}
