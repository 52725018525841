import React from 'react';
import {connect} from 'react-redux';
import {Button} from "@mui/material"
import {t} from '../../../utils/Translator';
import List from './list';
import DevicesService from "../../../services/DevicesService";
import moment from "moment";
import constants from "../../../config/constants";
import events from "../../../utils/Events";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CustomerDeviceListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            item: null,
            items: []
        }
        this.filteredDevices = null
    }

    componentDidMount() {
        DevicesService.ownerDevices().then(devices => {
            this.setState({items: devices})
        })
        events.on("filteredDevices", this.onDeviceFilter)
    }

    componentWillUnmount() {
        events.off("filteredDevices", this.onDeviceFilter)
    }

    onDeviceFilter(devices) {
        this.filteredDevices = devices
    }

    render() {
        const {onHide} = this.props;
        const exportData = this.filteredDevices == null ? this.state.items : this.filteredDevices;

        return <React.Fragment>
            <div className="container-wrapper grow">
                <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm overflow">
                    <div className="flex-grow-1 overflow">
                        <div className="container p-0">
                            <List items={this.state.items} enableDelete={false} enableSelection={false}/>

                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container d-flex">
                            <ExcelFile
                                element={<Button variant="contained" size="large" className="btn-green mr-2"><i className="mdi mdi-download"></i>{t('reportExport')}
                                </Button>}>
                                <ExcelSheet data={exportData} name={t("deviceTitle")}>
                                    <ExcelColumn label={t('reportDevice')} value="name"/>
                                    <ExcelColumn label={t('deviceIdentifier')}
                                                 value={col => t(col.getFormattedProperty('uniqueId'))}/>
                                    <ExcelColumn label={t('serverRegistration')}
                                                 value={col => col.attributes["registrationDate"] ? moment(new Date(col.attributes["registrationDate"])).format(constants.dateFormat) : ""}/>
                                    <ExcelColumn label={t('Exp. date')} value={col =>
                                        col.attributes["expirationDate"] ? moment(new Date(col.attributes["expirationDate"])).format(constants.dateFormat) : ""
                                    }/>
                                    <ExcelColumn label={t('customer')}
                                                 value={col => col.attributes['createdBy'] ? col.attributes['createdBy'] : ""}/>
                                </ExcelSheet>

                            </ExcelFile>
                            <Button variant="contained" size="large" className="btn-red" onClick={onHide}>
                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    // items: state.devices.devices
})

export default connect(mapStateToProps, null)(CustomerDeviceListPage);