import '@fortawesome/fontawesome-free/css/all.min.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Pencil from '@mui/icons-material/Edit';
import { Button, IconButton } from "@mui/material";
import 'bootstrap-css-only/css/bootstrap.min.css';
import { MDBDataTable } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
import moment from "moment";
import React from 'react';
import { Row } from 'react-bootstrap';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import { App } from "../../../App";
import "../../../assets/scss/datatable.css";
import constants from "../../../config/constants";
import DevicesService from "../../../services/DevicesService";
import Events from '../../../utils/Events';
import { t } from '../../../utils/Translator';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import TextFilter from "../../Controls/TextFilter";


export default class List extends React.Component {

    static deleteEvent = false

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            userFilter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
            userDevices: null,
            items: App.App.user.administrator ? {} : props.items
        }

        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);

        this.columns = [
            {
                label: t("sharedDevice"),
                field: "name",
                sort: 'asc',
                height: 50,
                width: "500px"
            },
            {
                label: t("deviceCategory"),
                field: "category",
                sort: 'asc',
                width: 150
            },
            {
                label: t("deviceIdentifier"),
                field: "uniqueId",
                sort: 'asc',
                width: 150
            },
            {
                label: t("deviceModel"),
                field: "model",
                sort: 'asc',
                width: 150
            },
            {
                label: t("deviceStatus"),
                field: "status",
                sort: 'asc',
                width: 150
            },
            {
                label: t("deviceContact"),
                field: "contact",
                sort: 'asc',
                width: 150
            },
        ]

        if (App.App.user.administrator) {
            this.columns.push({
                label: t("deviceOwner"),
                field: "attributes.owner",
                sort: "enabled",
                width: 150
            })
            this.columns.push({
                label: t("createdBy"),
                field: "attributes.createdBy",
                sort: "enabled",
                width: 150
            })
            this.columns.push({
                label: t("serverRegistration"),
                field: "attributes.registrationDate",
                sort: "enabled",
                width: 150
            })
        }

        this.columns.push({
            label: t("userExpirationTime"),
            field: "attributes.expirationDate",
            sort: 'asc',
            width: 150
        })

        this.columns.push({
            label: t("deviceLastUpdate"),
            field: "lastUpdate",
            sort: 'asc',
            width: 150
        })

        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;

        if (enableDelete)
            this.columns.push({
                label: t("actions"),
                field: "sharedRemove",
                sort: "disabled",
                width: 350
            })

        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;

        if (enableSelection)
            this.columns.splice(0, 0, {
                label: "",
                field: "checkBox",
                sort: "disabled",
                width: 150
            })

    }

    componentDidMount() {
        if (App.App.user.administrator) {
            DevicesService.listAll({}, false).then(async devs => this.setState({ items: devs }))
        }
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;
        if (this.props.userId && enableSelection) {
            const userId = this.props.userId;
            DevicesService.list({ userId: userId }, false).then(devs => {
                let userDevices = []
                devs.forEach(d => {
                    userDevices.push(d)
                })
                if (userDevices.length === 0) userDevices = null
                this.setState({ userDevices: userDevices })
            })

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items) {
            this.setState({ items: nextProps.items })
        }
    }

    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });
    }

    setFilter(value) {
        if (value !== this.state.filter) {
            Events.emit("filteredDevices", value)
            this.setState({ filter: value })
        }
    }

    setUserFilter(value) {
        if (value !== this.state.userFilter) {
            Events.emit("filteredUserDevices", value)
            this.setState({ userFilter: value })
        }
    }

    static filterValues(values, filter) {
        if (filter && filter !== '') {
            return Object.values(values).filter(o => {
                let owner = o.attributes['owner']
                if (owner === undefined || owner === null) owner = ""
                let customer = o.attributes['createdBy']
                if (customer === undefined || customer === null) customer = ""
                let contact = o.contact
                if (contact === undefined || contact === null) contact = ""
                return o.uniqueId.toLowerCase().includes(filter.toLowerCase())
                    || o.name.toLowerCase().includes(filter.toLowerCase())
                    || contact.toLowerCase().includes(filter.toLowerCase())
                    || owner.toLowerCase().includes(filter.toLowerCase())
                    || customer.toLowerCase().includes(filter.toLowerCase())
            });
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode != undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({ selectedItems: [] });
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o != id);
        } else {
            selectedItems.push(id);
        }
        this.setState({ selectedItems: selectedItems, allSelected: false }, callback);
    }

    selectAll() {
        const items = this.state.items
        const values = List.filterValues(items, this.state.filter).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: selectedItems, allSelected: true }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const items = this.state.items;
        const values = List.filterValues(items, this.state.filter).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: [], allSelected: false }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }

    render() {
        let items = this.state.items
        const userDevs = this.state.userDevices
        const userValues = userDevs ? List.filterValues(userDevs, this.state.userFilter).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : null;
        const values = List.filterValues(items, this.state.filter).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter != undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete != undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;

        const userDevRows = []
        if (userValues !== null)
            userValues.forEach(row => {
                userDevRows.push(
                    {
                        name: <div searchvalue={row.name.ucFirst()}>{
                            t(String(row.name.substring(0, String(row.name).length > 15 ? 15 : row.name.length).concat(String(row.name).length > 15 ? "..." : "")))
                        }</div>,
                        email: t(row.email),
                        uniqueId: t(row.getFormattedProperty('uniqueId')).substring(0, String(row.uniqueId).length > 15 ? 15 : row.uniqueId).concat(String(row.uniqueId).length > 15 ? "..." : ""),
                        status:
                            <div className={
                                (() => {
                                    if (row.status === 'online') {
                                        return 'text-success';
                                    } else if (row.status === 'offline') {
                                        return 'text-danger'
                                    } else {
                                        return 'text-warning'
                                    }
                                })()
                            }>{t(row.getFormattedProperty('status')).ucFirst()}
                            </div>,
                        contact: <div searchvalue={row.contact ? String(row.contact).ucFirst() : ""}>{
                            t(row.getFormattedProperty('contact'))
                        }</div>,
                        "attributes.owner": <div
                            searchvalue={row.attributes['owner'] ? String(row.attributes['owner']).ucFirst() : ""}>{
                                t(row.getAttribute('owner'))
                            }</div>,
                        "attributes.createdBy": <div
                            searchvalue={row.attributes['createdBy'] ? String(row.attributes['createdBy']).ucFirst() : ""}>{
                                t(row.getAttribute('createdBy'))
                            }</div>,
                        "attributes.registrationDate": <div searchvalue={row.attributes["registrationDate"] ?? 0}>{
                            row.attributes["registrationDate"] ? moment(new Date(row.attributes["registrationDate"])).format(constants.reportDateFormat) : ""
                        }</div>,
                        "attributes.expirationDate":
                            <div searchvalue={row.attributes["expirationDate"] ?? 0}>{
                                row.attributes["expirationDate"] ? moment(new Date(row.attributes["expirationDate"])).format(constants.reportDateFormat) : ""
                            }</div>
                        ,
                        "lastUpdate": <div
                            searchvalue={!isNaN(row.lastUpdate) ? (new Date(row.lastUpdate).getTime()) : 0}>{
                                t(row.getFormattedProperty('lastUpdate'))
                            }</div>,
                        "sharedRemove":
                            <div className="flexbox-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                <IconButton style={{
                                    border: "none",
                                    outline: "none"
                                }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                                    if (List.deleteEvent) {
                                        List.deleteEvent = false;
                                        return
                                    }
                                    this.selectItem(row.pk)
                                }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                                <IconButton style={{
                                    border: "none",
                                    outline: "none"
                                }} color="error" className="p-0 mr-2" onClick={() => {
                                    if (!row.disabled || App.App.user.administrator) {
                                        this.deleteItem(row.pk)
                                        List.deleteEvent = true;
                                    }
                                }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                            </div>,
                    }
                )
            })

        const rows = []

        values.forEach(row => {
            rows.push(
                {
                    name: <div searchvalue={row.name.ucFirst()}>{
                        !row.name ? "" : String(row.name).ellipsisEnd()
                    }</div>,
                    email: t(row.email),
                    uniqueId: String(row.getFormattedProperty('uniqueId', false)).ellipsisEnd(),
                    status:
                        <div searchvalue={row.status} className={
                            (() => {
                                if (row.status === 'online') {
                                    return 'text-success';
                                } else if (row.status === 'offline') {
                                    return 'text-danger'
                                } else {
                                    return 'text-warning'
                                }
                            })()
                        }>{t(row.getFormattedProperty('status')).ucFirst()}
                        </div>,
                    contact: <div searchvalue={row.contact ? String(row.contact).ucFirst() : ""}>{
                        t(row.getFormattedProperty('contact', false)).ellipsisEnd()
                    }</div>
                    ,
                    "attributes.owner": <div
                        searchvalue={row.attributes['owner'] ? String(row.attributes['owner']).ucFirst() : ""}>{
                            t(row.getAttribute('owner', false)).ellipsisEnd()
                        }</div>,
                    "attributes.createdBy":
                        <div
                            searchvalue={row.attributes['createdBy'] ? String(row.attributes['createdBy']).ucFirst() : ""}>{
                                t(row.getAttribute('createdBy', false)).ellipsisEnd()
                            }</div>,
                    "attributes.registrationDate": <div searchvalue={row.attributes["registrationDate"] ?? 0}>{
                        row.attributes["registrationDate"] ? moment(new Date(row.attributes["registrationDate"])).format(constants.reportDateFormat) : ""
                    }</div>,
                    "attributes.expirationDate":
                        <div searchvalue={row.attributes["expirationDate"] ?? 0}>{
                            row.attributes["expirationDate"] ? moment(new Date(row.attributes["expirationDate"])).format(constants.reportDateFormat) : ""
                        }</div>
                    ,
                    "lastUpdate": <div searchvalue={!isNaN(row.lastUpdate) ? (new Date(row.lastUpdate).getTime()) : 0}>{
                        t(row.getFormattedProperty('lastUpdate'))
                    }</div>,
                    "sharedRemove":
                        <div className="flexbox-container"
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                                if (List.deleteEvent) {
                                    List.deleteEvent = false;
                                    return
                                }
                                this.selectItem(row.pk)
                            }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="error" className="p-0 mr-2" onClick={() => {
                                if (!row.disabled || App.App.user.administrator) {
                                    this.deleteItem(row.pk)
                                    List.deleteEvent = true;
                                }
                            }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                        </div>
                    ,
                    checkBox: enableSelection ? <CustomCheckbox checked={this.isItemSelected(row.pk)} onChange={() => {
                        this.selectItem(row.pk)
                    }} />
                        : null,
                }
            )
        })

        const data = {
            columns: this.columns,
            rows: rows
        }

        const userColumns = this.columns !== null ? [...this.columns] : null

        if (enableSelection && userColumns !== null)
            userColumns.shift();

        const userData = {
            columns: userColumns,
            rows: userDevRows
        }

        return <React.Fragment>
            <header className="header-small w-100">
                <div className="container d-flex pb-0 w-100">
                    <h3 className="text-white"><i className="mdi mdi-car"></i> {
                        t('deviceTitle') + " - " + (values.length ? values.length : 0)}</h3>
                </div>
            </header>

            <div className="p-2">
                {enableSelection ?
                    <div className="list-tools">
                        {enableFilter ?
                            <React.Fragment><TextFilter
                                onFilter={(value) => this.setUserFilter(value)} /></React.Fragment>
                            : null}
                        <hr />
                    </div>
                    : null
                }

                {enableSelection ?
                    <Row className="ml-2">
                        <h3 className="internal-title"><i className="mdi mdi-car"></i>{
                            t('userDevices') + " " + (userDevs !== null && userDevs.length ? userDevs.length : 0)
                        }</h3>
                        <ExcelFile
                            element={<Button className="ml-3 btn-green" variant="contained" size="large"><i
                                className="mdi mdi-download"></i>{t('reportExport')}
                            </Button>}>
                            <ExcelSheet data={userValues} name={t("deviceTitle")}>
                                <ExcelColumn label={t('reportDevice')} value="name" />
                                <ExcelColumn label={t('sharedDisabled')} value="disabled" />
                                <ExcelColumn label={t('deviceCategory')}
                                    value={col => t('category' + col.category.ucFirst())} />
                                <ExcelColumn label={t('deviceIdentifier')}
                                    value={col => t(col.getFormattedProperty('uniqueId'))} />
                                <ExcelColumn label={t('deviceModel')}
                                    value={col => t(col.getFormattedProperty('model'))} />
                                <ExcelColumn label={t('deviceContact')}
                                    value={col => t(col.getFormattedProperty('contact'))} />
                                <ExcelColumn label={t('Exp. date')} value={col =>
                                    col.attributes["expirationDate"] ? moment(new Date(col.attributes["expirationDate"])).format(constants.dateFormat) : ""
                                } />
                                <ExcelColumn label={t('Reg. date')} value={col =>
                                    col.attributes["registrationDate"] ? moment(new Date(col.attributes["registrationDate"])).format(constants.dateFormat) : ""
                                } />
                                 <ExcelColumn label={t('Owner')}
                                    value={col => t(col.getFormattedProperty('owner'))} />
                                <ExcelColumn label={t('Created by')}
                                    value={col => t(col.getFormattedProperty('owner'))} />
                            </ExcelSheet>
                        </ExcelFile>
                    </Row>
                    : null
                }

                {
                    enableSelection ?
                        <section className="ml-2 mr-2 statisc-table">
                            <div className="flex-grow-1 overflow">
                                <MDBDataTable
                                    infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                    noRecordsFoundLabel={t("sharedEmtpyList")}
                                    paginationLabel={[t("previous"), t("next")]}
                                    entriesLabel={t("showEntries")}
                                    striped
                                    hover
                                    searching={false}
                                    bordered
                                    small
                                    noBottomColumns={true}
                                    data={userData}
                                    sortRows={['name', 'status', 'contact', 'attributes.owner', 'attributes.createdBy', 'attributes.expirationDate', 'attributes.registrationDate', 'lastUpdate']}
                                />
                            </div>
                        </section>
                        : null
                }

                <div className="list-tools">
                    {enableSelection ?
                        <div className="list-controls">
                            <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()} />
                        </div>
                        : null}
                    {enableFilter ?
                        <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)} /></React.Fragment>
                        : null}
                    <hr />
                </div>

                <section className="ml-2 mr-2 statisc-table">
                    <div className="flex-grow-1 overflow">
                        <MDBDataTable id="myTableID"
                            infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                            noRecordsFoundLabel={t("sharedEmtpyList")}
                            paginationLabel={[t("previous"), t("next")]}
                            entriesLabel={t("showEntries")}
                            striped
                            hover
                            searching={false}
                            bordered
                            small
                            autoWidth={false}
                            noBottomColumns={true}
                            data={data}
                            sortRows={['name', 'status', 'contact', 'attributes.owner', 'attributes.createdBy', 'attributes.expirationDate', 'attributes.registrationDate', 'lastUpdate']}
                        />
                    </div>
                </section>
            </div>
        </React.Fragment>
    }

    // getList(enableSelection, enableDelete, i) {
    //     return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>
    //         {enableSelection ?
    //             <CustomCheckbox checked={this.isItemSelected(i.pk)} onChange={() => {
    //                 this.selectItem(i.pk)
    //             }}/>
    //             : null}
    //         <div className="content" onClick={() => {
    //             if (!i.disabled || App.App.user.administrator)
    //                 this.selectItem(i.pk)
    //         }}>
    //             <strong className="name">{i.name}</strong>
    //             <span className="value">
    //                              <span className="attribute">
    //                                 <span className="attr-name">{t('sharedDisabled')}:</span>
    //                                 <span className="attr-value">{t(String(i.disabled))}</span>
    //                             </span>
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('deviceCategory')}:</span>
    //                                 <span className="attr-value">{t('category' + i.category.ucFirst())}</span>
    //                             </span>
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('deviceIdentifier')}:</span>
    //                                 <span className="attr-value">{t(i.getFormattedProperty('uniqueId'))}</span>
    //                             </span>
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('deviceModel')}:</span>
    //                                 <span className="attr-value">{t(i.getFormattedProperty('model'))}</span>
    //                             </span>
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('deviceStatus')}:</span>
    //                                 <span className="attr-value">{t(i.getFormattedProperty('status'))} <i
    //                                     className="danger"> </i></span>
    //                             </span>
    //
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('deviceContact')}:</span>
    //                                 <span className="attr-value">{t(i.getFormattedProperty('contact'))} <i
    //                                     className="danger"> </i></span>
    //                             </span>
    //
    //                 {
    //                     App.App.user.administrator ?
    //                         <span className="attribute">
    //                                         <span className="attr-name">{t('deviceOwner')}:</span>
    //                                         <span className="attr-value">{t(i.getAttribute('owner'))} <i
    //                                             className="danger"> </i></span>
    //                                     </span> : null
    //                 }
    //                 {
    //                     App.App.user.administrator ?
    //                         <span className="attribute">
    //                                         <span className="attr-name">{t('createdBy')}:</span>
    //                                         <span className="attr-value">{t(i.getAttribute('createdBy'))} <i
    //                                             className="danger"> </i></span>
    //                                     </span> : null
    //                 }
    //                 <span className="attribute">
    //                                 <span className="attr-name">{t('Exp. date')}:</span>
    //                                 <span
    //                                     className="attr-value">{i.attributes["expirationDate"] ? moment(new Date(i.attributes["expirationDate"])).format(constants.dateFormat) : ""}</span>
    //                             </span>
    //
    //                             <span className="attribute">
    //                                 <span className="attr-name">{t('Last Update')}:</span>
    //                                 <span className="attr-value">{t(i.getFormattedProperty('lastUpdate'))} </span>
    //                             </span>
    //                         </span>
    //         </div>
    //         {enableDelete ? <div className="actions">
    //             <Button variant="default" className="danger" onClick={() => {
    //                 if (!i.disabled || App.App.user.administrator)
    //                     this.deleteItem(i.pk)
    //             }}><i className="mdi mdi-delete"></i></Button>
    //         </div> : null}
    //     </ListGroup.Item>
    // }

}