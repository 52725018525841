import React from 'react';
import AttributesPage from '../Attributes';
import { Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import {Button} from "@mui/material"
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ComputedAttribute } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import { store } from '../../../store';
import CustomSelect from '../../Controls/CustomSelect';


export default class ComputedAttributeForm extends React.Component {
    constructor(props) {
        super(props);

        const attributeOptions = [];
        Object.values(store.getState().position_attributes.position_attributes).map(o => {
            attributeOptions.push({ label: t(o.name), value: o.key })
        })



        attributeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);


        attributeOptions.unshift({ label: t('reportCustom'), value: '' })


        const typeOptions = [
            { value: 'string', label: t('sharedTypeString') },
            { value: 'boolean', label: t('sharedTypeBoolean') },
            { value: 'number', label: t('sharedTypeNumber') },
        ]

        this.state = {
            attributesPage: false,
            item: new ComputedAttribute().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            attributeOptions: attributeOptions,
            typeOptions: typeOptions,
        }


        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.changeType = this.changeType.bind(this);
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        item.attributes = values.parameters;
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new ComputedAttribute().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new ComputedAttribute().deserialize(deepClone(newProps.item)) }, () => {
            //this.constructConfig(this.state.item.type);
        })
    }

    componentDidMount() {
        this.constructConfig(this.state.item.type);
    }

    constructConfig(type) {
        const command = store.getState().known_commands.known_commands[type];
        const { item } = this.state;
        const fields = [];
        if (command && command.parameters) {
            const parameters = command.parameters;
            parameters.map(p => {
                const config = {};
                config.key = p.key;
                config.label = t(p.name);
                if (item.attributes && item.attributes[config.key]) {
                    config.value = item.attributes[config.key];
                }


                switch (p.valueType) {
                    case 'number':
                        config.type = 'number';
                        config.minValue = p.minValue;
                        config.maxValue = p.maxValue;
                        config.step = p.allowDecimals !== undefined && (!p.allowDecimals) ? 1 : 0.01;
                        break;
                    case 'boolean':
                        config.type = 'boolean';
                        break;
                    default:
                        if (p.dataType === 'timezone') {
                            config.type = 'select';
                            config.options = [];
                            Object.values(store.getState().timezones.timezones).map(o => {
                                config.options.push({ name: o.key, value: o.key })
                            })
                            config.options.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                        } else {
                            config.type = 'text';
                        }
                }
                fields.push(config);
            })
        }

        this.setState({
            fields: fields
        })
    }

    changeType(value, setFieldValue) {
        setFieldValue('type', value);
        this.constructConfig(value);

    }



    render() {
        const { sending, item, attributeOptions, typeOptions } = this.state;

        const schema = yup.object({
            description: yup.string().required(),
            expression: yup.string().required(),
            attribute: yup.string().optional(),
            type: yup.string().optional(),
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="commandsAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        description: item.description,
                        expression: item.expression,
                        attribute: item.attribute,
                        type: item.attribute && item.attribute.id > 0 ? t('sharedType' + item.attributeType.ucFirst()) : item.type || 'string',
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid,
                        setFieldValue }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i className="mdi mdi-cast"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedComputedAttribute')}</h3>


                                            <FormGroup>
                                                <FormLabel>{t("sharedDescription")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    defaultValue={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedAttribute")}</FormLabel>
                                                <CustomSelect options={attributeOptions} name="attribute" defaultValue={values.attribute} isClearable onChange={(name, value) => {
                                                    const state = store.getState().position_attributes.position_attributes[value];
                                                    const type = state ? t('sharedType' + state.valueType.ucFirst()) : '';
                                                    setFieldValue('type', type);
                                                    setFieldValue(name, value);
                                                }} />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedExpression")}</FormLabel>
                                                <FormControl
                                                    as="textarea"
                                                    name="expression"
                                                    defaultValue={values.expression}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.expression}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedType")}</FormLabel>
                                                {values.attribute ? <FormControl
                                                    readOnly
                                                    type="text"
                                                    name="type"
                                                    value={values.type}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.valueType}
                                                /> : <CustomSelect
                                                        name="type"
                                                        options={typeOptions}
                                                        defaultValue={values.type}
                                                        onChange={(name, value) => {                                                            
                                                            setFieldValue(name, value)
                                                        }}
                                                        isInvalid={!!errors.valueType}
                                                    />}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {/*<Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                        </Button>*/}
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}