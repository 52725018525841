import React from 'react';
import { connect } from 'react-redux';
import { Button } from "@mui/material"
import { t } from '../../../utils/Translator';
import { AppService, MaintenancesService, ExpensesService } from '../../../services';
import Page from '../../Page';
import MaintenanceForm from './form';
import { Maintenance } from '../../../models';
import List from './list';
import ExpenseForm from '../Expenses/form';

class MaintenanceListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            item: null
        }

        this.hideForm = this.hideForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    async deleteItem(id) {
        if (await AppService.showDeleteConfirm()) {
            MaintenancesService.destroy(id);
        }
    }

    showForm(id) {
        this.setState({
            showForm: true,
            item: id ? new Maintenance().deserialize(this.props.items[id]) : new Maintenance()
        })
    }

    async onSave(item) {
        console.log("saving item",item)
        try {
            if (item.id) {
                await MaintenancesService.update(item)
            } else {
                const newItem = await MaintenancesService.save(item);
                item.id = newItem.id
            }
            this.hideForm();
        } catch (ex) {
            AppService.showError(ex)
        }

    }

    hideForm() {
        this.setState({
            showForm: false,
            item: null
        })
    }

    render() {
        const { onHide } = this.props;
        return <React.Fragment>
            {this.state.showForm ?
                <Page onHide={this.hideForm} title={<React.Fragment><h3 className="title"><i className="mdi mdi-wrench"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedMaintenance')}</h3></React.Fragment>}>
                    <MaintenanceForm item={this.state.item} onSave={this.onSave} onHide={this.hideForm}></MaintenanceForm>
                </Page>
                : null}

            {this.state.showExpenseForm ?
                <Page onHide={() => { this.setState({ showExpenseForm: false }) }} title={<React.Fragment><h3 className="title"><i className="mdi mdi-account-key"></i> {t('sharedAdd')} {t('sharedExpenses')}</h3></React.Fragment>}>
                    <ExpenseForm maintenance={true} onSave={async (item) => {
                        try {
                            await ExpensesService.save(item);
                            this.setState({ showExpenseForm: false })
                        } catch (ex) {
                            AppService.showError(ex)
                        }
                    }} onHide={() => { this.setState({ showExpenseForm: false }) }}></ExpenseForm>
                </Page>
                : null}


            <div className="container-wrapper grow">
                <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm">
                    <div className="flex-grow-1 overflow">
                        <div className="container p-0">
                            <List items={this.props.items} onDelete={this.deleteItem} onSelect={this.showForm} enableDelete={true} enableSelection={false} />
                        </div>

                    </div>
                    <footer className="footer">
                        <div className="container d-flex">
                            {/* todo reenable <div className="flex-grow-1 d-flex">
                                <Button variant="contained" size="large" className="align-self-stretch btn-blue" onClick={() => { this.setState({ showExpenseForm: true }) }}>
                                    <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')} {t('sharedExpenses')}
                                </Button>
                            </div> */}
<div className="flex-grow-1 d-flex">
                                <Button variant="contained" size="large" className="align-self-stretch btn-blue mr-2" onClick={() => this.showForm('')}>
                                    <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')}
                                </Button>
                                </div>
                            <Button variant="contained" size="large" className="btn-red" onClick={onHide}>
                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    items: state.maintenances.maintenances
})

export default connect(mapStateToProps, null)(MaintenanceListPage);