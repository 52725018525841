import * as pkg from '../../package.json';

const config = {
    appName: pkg.name,
    appVersion: pkg.version,
    refreshPeriod: 60 * 1000,
    reconnectTimeout: 5 * 1000,
    reportTimeout: 120 * 1000,

    normalPadding: 10,

    windowWidth: 800,
    windowHeight: 600,

    formFieldWidth: 275,

    dateTimeFormat24: 'DD-MM-YYYY HH:mm:ss',
    dateTimeFormat12: 'DD-MM-YYYY hh:mm:ss A',
    timeFormat24: 'HH:mm',
    timeFormat12: 'hh:mm A',
    dateFormat: 'DD-MM-YYYY',
    reportDateFormat: 'YYYY-MM-DD',
    weekStartDay: 1,

    deviceWidth: 400,
    toastWidth: 300,

    reportHeight: 250,

    columnWidthNormal: 100,

    mapDefaultLat: 48.8567,
    mapDefaultLon: 2.3508,
    mapDefaultZoom: 4,

    mapRouteColor: [
        '#F06292',
        '#BA68C8',
        '#4DD0E1',
        '#4DB6AC',
        '#FF8A65',
        '#A1887F'
    ],
    mapRouteWidth: 5,

    mapTextColor: 'rgba(50, 50, 50, 1.0)',
    mapTextStrokeColor: 'rgba(255, 255, 255, 1.0)',
    mapTextStrokeWidth: 2,
    mapTextOffset: 2,
    mapTextFont: 'bold 12px sans-serif',


    device: {
        colors: {
            'online': 'rgba(0, 172, 66, .8)',
            'offline': 'rgba(212, 48, 67, .8)',
            'unknown': 'rgba(250, 190, 77, .8)',
        },
        scale: {
            normal: 1,
            selected: 1.3
        }
    },

     mapTypes: {
        leaflet: {
            enabled: false,
            default: false,
            mapServices: {
                   'default': {
                    name: 'Default',
                    enabled: true,
                    url: 'https://mt0.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga',
                    subdomains: [],
                    maxZoom: 18
                },
                'wikimedia': {
                    name: 'mapWikimedia',
                    enabled: true,
                    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
                    subdomains: ['a', 'b', 'c'],
                    maxZoom: 18
                },
                'ArcGis Topo': {
                    name: 'ArcGis Topo',
                    enabled: true,
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png',
                    subdomains: [],
                    maxZoom: 18
                },
                 'ArcGis Imagery': {
                    name: 'ArcGis Imagery',
                    enabled: true,
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png',
                    subdomains: [],
                    maxZoom: 18
                },
                'custom': {
                    name: 'Custom',
                    enabled: false,
                    url: '',
                    subdomains: [],
                    maxZoom: 18
                },
                'bingAerial': {
                    name: 'mapBingAerial',
                    enabled: false
                },
                'osm': {
                    name: 'OSM',
                    enabled: true,
                    url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    subdomains: ['a', 'b', 'c'],
                    maxZoom: 18
                },
                'carto': {
                    name: 'Carto',
                    enabled: false,
                    url: 'https://{s}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
                    subdomains: ['sat01', 'sat02', 'sat03', 'sat04'],
                    maxZoom: 18
                },
                'yandexSat': {
                    name: 'YandexSat',
                    enabled: false,
                    url: 'https://{s}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
                    subdomains: ['sat01', 'sat02', 'sat03', 'sat04'],
                    maxZoom: 18
                },
                'yandexMap': {
                    name: 'YandexMap',
                    enabled: false,
                    url: 'https://{s}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
                    subdomains: ['sat01', 'sat02', 'sat03', 'sat04'],
                    maxZoom: 18
                },
                'baidu': {
                    name: 'Baidu',
                    enabled: false,
                    url: 'http://{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y=M{y}&z={z}&styles=pl',
                    subdomains: ['online0', 'online1', 'online2', 'online3', 'online4'],
                    maxZoom: 18
                },
                'google': {
                    name: 'Google',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },
                'hybrid': {
                    name: 'Google Hybrid',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },
                'terrain': {
                    name: 'Google Terrain',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },
                'traffic': {
                    name: 'Google Traffic',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18

             },
                'OSM Cycling': {
                    name: 'OSM cycling',
                    enabled: false,
                    url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
                    subdomains: ['a', 'b', 'c'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },

                    'Transportation': {
                        name: 'Public Transport ',
                        enabled: false,
                        url: 'https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png',
                        subdomains: ['a', 'b', 'c'],
                        maxZoom: 18,
                        maxNativeZoom: 18 
                        
                },
                 'Stadiamaps dark': {
                    name: 'Dark',
                    enabled: true,
                    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
                    subdomains: ['a', 'b', 'c'],
                    maxZoom: 18
                }
            },
            mapOptions: {
                is_full_screen: {
                    enabled: true,
                    default: false,
                    value: true
                },
                show_routes: {
                    enabled: true,
                    default: false,
                    value: false
                },
                show_devices: {
                    enabled: false,
                    default: true,
                    value: true
                },
                show_names: {
                    enabled: true,
                    default: true,
                    value: true
                },
                fit_objects: {
                    enabled: true,
                    default: false,
                    value: false
                },
                show_geofences: {
                    enabled: true,
                    default: true,
                    value: true
                },
                cluster_enabled: {
                    enabled: true,
                    default: true,
                    value: true
                },
                follow_devices: {
                    enabled: true,
                    default: false,
                    value: false
                },
            },

        },
        openlayers: {
            enabled: true,
            default: true,
            location: '/utils/maps/openlayers',
            mapOptions: {
                is_full_screen: {
                    enabled: true,
                    default: false,
                    value: true

                },
                show_routes: {
                    enabled: true,
                    default: false
                },
                show_devices: {
                    enabled: false,
                    default: true
                },
                show_names: {
                    enabled: true,
                    default: true
                },
                fit_objects: {
                    enabled: true,
                    default: false
                },
                show_geofences: {
                    enabled: true,
                    default: true
                },
                cluster_enabled: {
                    enabled: true,
                    default: false
                },
                follow_devices: {
                    enabled: true,
                    default: false
                },

            },
            mapServices: {
                'default': {
                    name: 'mapWikimedia',
                    enabled: true,
                    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
                    maxZoom: 18
                },
                'custom': {
                    name: 'mapCustom',
                    enabled: true,
                    url: '',
                    maxZoom: 18
                },
                'osm': {
                    name: 'mapOsm',
                    enabled: true,
                    url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    maxZoom: 18
                },
                'carto': {
                    name: 'mapCarto',
                    enabled: true,
                    url: 'https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
                    maxZoom: 18
                },
                'yandexSat': {
                    name: 'mapYandexSat',
                    enabled: true,
                    url: 'https://sat0{1-4}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}',
                    maxZoom: 18
                },
                'yandexMap': {
                    name: 'mapYandexMap',
                    enabled: true,
                    url: 'https://vec0{1-4}.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}',
                    maxZoom: 18
                },
                'baidu': {
                    name: 'mapBaidu',
                    enabled: true,
                    url: 'http://online{}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl',
                    maxZoom: 18
                },
                'bingRoad': {
                    name: 'mapBingRoad',
                    enabled: true
                },
                'bingAerial': {
                    name: 'mapBingAerial',
                    enabled: true
                },
                'bingHybrid': {
                    name: 'mapBingHybrid',
                    enabled: true
                },
                'bingRoad': {
                    name: 'mapBingRoad',
                    enabled: true
                },
                'bingRoad': {
                    name: 'mapBingRoad',
                    enabled: true
                },
                'google': {
                    name: 'mapGoogle',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=m&hl=es&x={x}&y={y}&z={z}&s=Ga&gl=es',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },
                'hybrid': {
                    name: 'mapGoogleHybrid',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=y&hl=es&x={x}&y={y}&z={z}&s=Ga&gl=es',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 21,
                    maxNativeZoom: 21
                },
                'terrain': {
                    name: 'mapGoogleTerrain',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=p&hl=es&x={x}&y={y}&z={z}&s=Ga&gl=es',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 18,
                    maxNativeZoom: 18
                },
                'traffic': {
                    name: 'mapGoogleTraffic',
                    enabled: true,
                    url: 'https://{s}.google.com/vt/lyrs=m,traffic&hl=es&x={x}&y={y}&z={z}&s=Ga&gl=es',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    maxZoom: 21,
                    maxNativeZoom: 21
                }
            },
        }
    },

    notifications: {
        enabled: true,
        position: 'topRight',
        maxStack: 4,
        mobileMaxStack: 1,
        duration: 5000,
        soundEnabled: false,
        sound: 'sounds/beep.wav',
    },

    selectedMapType: 'leaflet',
    mapDelay: 500,
    selectedZoom: 2,

    mapAccuracyColor: 'rgba(96, 96, 96, 1.0)',

    mapGeofenceTextColor: 'rgba(14, 88, 141, 1.0)',
    mapGeofenceColor: 'rgba(21, 127, 204, 1.0)',
    mapGeofenceOverlayOpacity: 0.2,
    mapGeofenceWidth: 5,
    mapGeofenceRadius: 9,

    coordinatePrecision: 6,
    numberPrecision: 2,

    reportGridStyle: 'borderTop: 1px solid lightgray',

    chartPadding: '20 40 10 10',
    chartMarkerRadius: 3,
    chartMarkerHighlightScaling: 1.5,

    enableStreetView: false,
    streetViewKey: '',

    position_attributes: ['address', 'fixTime', 'latitude', 'longitude', 'altitude', 'accuracy', 'valid', 'speed', 'course', 'protocol'],
    position_sensors: ['position.address', 'position.speed', 'position.motion'],
}

export default Object.assign(config, window._CONFIG || {});