import devices from "../store/devices";
import {Device} from "../models";
import events from "../utils/Events";
import ApiStoreService from "./ApiStoreService";

export class DevicesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'devices';
        this.storeName = devices;
    }

    async userDevices(userId) {
        const {data} = await this.api.get(this.baseUrl + '/user-device-ids?userId=' + userId);
        return data;
    }

    async load(params = {}) {
        // if(App.App.user.administrator) {
        //     params["all"] = true
        // }
        const items = await super.list(params);
        // update the store
        events.emit(devices.types.LOADED, {devices: items});
        return items;
    }

    async saveAcumulators(values = {}) {
        const {data} = await this.api.put(this.baseUrl + '/' + values.deviceId + '/accumulators', values);
        return data;
    }

    async ownerDevices() {
        const {data} = await this.api.get(this.baseUrl + '/owner');
        return this.transformListResponse(data);
    }

    transformSingleResponse(data) {
        const d = new Device().deserialize(data);
        return d;
    }

}

export default new DevicesService();