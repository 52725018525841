import React from 'react';
import AttributesPage from '../Attributes';
import { Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import {Button} from '@mui/material'
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Command } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import { store } from '../../../store';
import CustomSelect from '../../Controls/CustomSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import CustomNumberField from '../../Controls/CustomNumberField';


export default class SavedCommandForm extends React.Component {
    constructor(props) {
        super(props);

        const typeOptions = [];
        Object.values(store.getState().command_types.command_types).map(o => {
            typeOptions.push({ label: t('command' + o.type.ucFirst()), value: o.type })
        })
        typeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
        this.state = {
            attributesPage: false,
            item: new Command().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            typeOptions: typeOptions,
        }


        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.changeType = this.changeType.bind(this);
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        item.attributes = values.parameters;
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Command().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Command().deserialize(deepClone(newProps.item)) }, () => {
            this.constructConfig(this.state.item.type);
        })
    }

    componentDidMount() {
        this.constructConfig(this.state.item.type);
    }

    constructConfig(type) {
        const command = store.getState().known_commands.known_commands[type];
        const { item } = this.state;
        const fields = [];
        if (command && command.parameters) {
            const parameters = command.parameters;

            parameters.map(p => {
                const config = {};
                config.key = p.key;
                config.label = t(p.name);
                if (item.attributes && item.attributes[config.key]) {
                    config.value = item.attributes[config.key];
                }
                config.dataType = p.dataType;

                switch (p.valueType) {
                    case 'number':
                        config.type = 'number';
                        config.minValue = p.minValue;
                        config.maxValue = p.maxValue;
                        config.step = p.allowDecimals !== undefined && (!p.allowDecimals) ? 1 : 0.01;
                        break;
                    case 'boolean':
                        config.type = 'boolean';
                        break;
                    default:
                        if (p.dataType === 'timezone') {
                            config.type = 'select';
                            config.options = [];
                            Object.values(store.getState().timezones.timezones).map(o => {
                                config.options.push({ name: o.key, value: o.key })
                            })
                            config.options.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                        } else {
                            config.type = 'text';
                        }
                }
                fields.push(config);
            })
        }

        this.setState({
            fields: fields
        })
    }

    changeType(value, setFieldValue) {
        setFieldValue('type', value);
        this.constructConfig(value);

    }

    render() {
        const { sending, item } = this.state;

        const schema = yup.object({
            description: yup.string().required(),
            type: yup.string().required(),
            textChannel: yup.boolean(),

        });

        return <React.Fragment>

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        description: item.description,
                        type: item.type,
                        textChannel: item.textChannel,
                        parameters: item.attributes && item.attributes.parameters ? item.attributes.parameters : {}
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid,
                        setFieldValue }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className={"flex-grow-1 overflow " + (this.props.mode != 'send' ? 'pt-3' : '')}>
                                        <div className={"container " + (this.props.mode == 'send' ? 'p-0 m-0 ' : '')}>
                                            {this.props.mode !== 'send' ? <h3 className="internal-title"><i className="mdi mdi-apple-keyboard-command"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedSavedCommand')}</h3> : null}
                                            {this.props.mode !== 'send' ? <FormGroup>
                                                <FormLabel>{t("sharedDescription")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    defaultValue={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                />
                                            </FormGroup> : null}
                                            <FormGroup className="mt-3">
                                                <CustomCheckbox name="textChannel" value="textChannel" defaultChecked={values.textChannel} onChange={handleChange} label={t('commandSendSms')} />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("sharedType")}</FormLabel>
                                                <CustomSelect name="type" defaultValue={values.type} options={this.state.typeOptions} onChange={(name, value) => this.changeType(value, setFieldValue)} />


                                            </FormGroup>


                                            {this.state.fields ? this.state.fields.map(o => {
                                                return <FormGroup key={o.key}>
                                                    {o.type !== 'boolean' ?
                                                        <FormLabel>{t(o.label)}</FormLabel> : null}

                                                    {(() => {
                                                        switch (o.type) {
                                                            case 'number':
                                                                return <CustomNumberField
                                                                    type="number"
                                                                    name={'parameters.' + o.key}
                                                                    value={o.value}
                                                                    xdatatype={o.dataType}
                                                                    onChange={handleChange}
                                                                    min={o.minValue}
                                                                    max={o.maxValue}
                                                                    step={o.step}
                                                                />
                                                            case 'boolean':
                                                                return <CustomCheckbox name={'parameters.' + o.key} value={o.key} defaultChecked={o.value} onChange={handleChange} label={t(o.label)} />
                                                            case 'select':
                                                                return <FormControl
                                                                    as="select"
                                                                    name={'parameters.' + o.key}
                                                                    value={o.value}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">----</option>
                                                                    {o.options.map(o => {
                                                                        return (<option key={o.value} value={o.value}>{o.name}</option>)
                                                                    })}
                                                                </FormControl>
                                                            default:
                                                                return <FormControl
                                                                    type="text"
                                                                    name={'parameters.' + o.key}
                                                                    defaultValue={o.value}
                                                                    onChange={handleChange}
                                                                />
                                                        }
                                                    })()}
                                                </FormGroup>
                                            }) : null}
                                        </div>
                                    </div>
                                    {this.props.mode !== 'send' ? <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {/*<Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                        </Button>*/}
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" className="btn-blue" disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer> : null}
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}