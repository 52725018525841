import React from 'react';
import { connect } from 'react-redux';
import {Button} from "@mui/material"
import { t } from '../../../utils/Translator';
import { AppService, ExpensesService } from '../../../services';
import Page from '../../Page';
import ExpenseForm from './form';
import { Expense } from '../../../models';
import List from './list';
import {store} from '../../../store';

class ExpensesListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            item: null
        }

        this.hideForm = this.hideForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    async deleteItem(id) {
        if (await AppService.showDeleteConfirm()) {
            ExpensesService.destroy(id);
        }
    }

    showForm(id) {
        this.setState({
            showForm: true,
            item: (id ? new Expense().deserialize(this.props.items[id]) : new Expense())
        })
    }

    async onSave(item) {
        try {
            if (item.id) {
                await ExpensesService.update(item)
                
            } else {
                await ExpensesService.save(item);
            }
            this.hideForm();
        } catch (ex) {
            AppService.showError(ex)
        }
    }

    hideForm() {
        this.setState({
            showForm: false,
            item: null
        })
    }


    render() {
        const { onHide } = this.props;
        const { items } = this.props;
        const devices = store.getState().devices.devices
        
        Object.values(items).forEach(item => {
            if(item.deviceId > 0)  {
                item.deviceName = devices[item.deviceId] ? devices[item.deviceId].name : ""
            }
        });
        return <React.Fragment>
            {this.state.showForm ?
                <Page onHide={this.hideForm} title={<React.Fragment><h3 className="title"><i className="mdi mdi-account-key"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedExpenses')}</h3></React.Fragment>}>
                    <ExpenseForm item={this.state.item} onSave={this.onSave} onHide={this.hideForm}></ExpenseForm>
                </Page>
                : null}

            <div className="container-wrapper grow">
                <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm overflow">
                    <div className="flex-grow-1 overflow">
                        <div className="container p-0">
                            <List items={this.props.items} onDelete={this.deleteItem} onSelect={this.showForm} enableDelete={true} enableSelection={false} />
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container d-flex">
                            <div className="flex-grow-1 d-flex">
                                <Button variant="contained" size="large" className="align-self-stretch btn-blue" onClick={() => this.showForm('')}>
                                    <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')}
                                </Button>
                            </div>
                            <Button variant="contained" size="large" className="btn-red" onClick={onHide}>
                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
     items: state.expenses.expenses
})

export default connect(mapStateToProps, null)(ExpensesListPage);