import {BaseModel} from "./BaseModel";

export const fields = [{
    name: 'acceleration',
    type: 'int'
}, {
    name: 'braking',
    type: 'int'
}, {
    name: 'cornering',
    type: 'int'
}, {
    name: 'total',
    type: 'int'
}, {
    name: 'distance',
    type: 'float'
}, {
    name: 'hours',
    type: 'float'
}, {
    name: 'deviceId',
    type: 'int'
}, {
    name: 'startTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'endTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'metadata'
},{
    name: 'driverUniqueId',
    type: 'string'
}, {
    name: 'driverName',
    type: 'string'
}]

export class ReportEcoScore extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}