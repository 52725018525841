import React from 'react';
import CreatableSelect from 'react-select';

class EditableSelect extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.parseDefaultValue = this.parseDefaultValue.bind(this);
    }

    handleChange(e) {
        let value = e ? e.value : null;
        if (e) {
            if (this.props.isMulti) {
                value = [];
                e.map(v => {
                    value.push(v.value);
                })
                value = value.join(',');
            }
        } else {

        }
        this.props.onChange(this.props.name, value);
    }

    parseDefaultValue() {
        let value = null;
        const options = this.props.options || [];
        const defaultValue = this.props.defaultValue || null;
        if (defaultValue) {
            if (this.props.isMulti) {
                const selected = [];
                defaultValue.split(',').map(v => {
                    selected.push(options.find(o => String(o.value).valueOf().toLowerCase() === String(v).valueOf().toLowerCase()))
                })
                value = selected;
            } else {
                value = options.find(o => String(o.value).valueOf().toLowerCase() === String(defaultValue).valueOf().toLowerCase())
            }
            if (!value) {
                value = { label: defaultValue, value: defaultValue };
                options.unshift(value)
            }
        }
        return value;
    }

    render() {
        return (
            <React.Fragment>
                <CreatableSelect {...this.props} defaultValue={this.parseDefaultValue()} className="custom-select-component" classNamePrefix="custom-select" onChange={this.handleChange} />
            </React.Fragment>
        )
    }
}

export default EditableSelect;