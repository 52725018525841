import React from 'react';
import PropTypes from 'prop-types';
import {store} from '../../store';
import devices from '../../store/devices';
import events from '../../utils/Events';
import Events from '../../utils/Events';
import {t} from '../../utils/Translator';
import Interval from '../Interval';
import {AppService, CommandsService, DevicesService} from '../../services';
import app from '../../store/app';
import {App, customReadOnlyUser} from '../../App';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import CommandModal from '../DeviceStatus/command';
import {Command} from "../../models";

class DeviceItem extends React.PureComponent {
    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            showMenu: false,
            showAttributes: Object.values(store.getState().devices.device_attributes).filter(o => o.show === true)
        };


        this.device = Object.assign({}, props.device);
        this.rerender = this.rerender.bind(this);
        this.hideCommand = this.hideCommand.bind(this);
        this.showAttributesChanged = this.showAttributesChanged.bind(this);
    }

    showChanged(d) {
        if (!this.state.loading) {
            this.setState({loading: true});
            //d.setViewState('hidden', !d.getViewState('hidden'));
            store.dispatch(devices.actions.deviceToggled(d.id))
            events.emit(devices.types.UPDATED, d);
            //disallow repeat show/hide
            setTimeout(() => {
                this.setState({loading: false});
            }, 100)
        }
    }

    //PURE COMPONENT DON'T NEED THIS RIGHT NOW
    /*componentWillReceiveProps(props) {
        this.device = Object.assign({}, props.device);
    }

    shouldComponentUpdate(newProps, newState) {
        //check if someting has changed
        const changed=newProps.device.selected != this.props.device.selected || newProps.device.hidden != this.props.device.hidden || newProps.device.name != this.device.name || newProps.device.status != this.device.status || newProps.device.lastUpdate != this.device.lastUpdate || (this.position != newProps.position);
        console.log(changed);
        return changed;
    }*/

    rerender() {
        this.forceUpdate();
    }

    showAttributesChanged() {
        this.setState({
            showAttributes: Object.values(store.getState().devices.device_attributes).filter(o => o.show === true)
        })
    }

    componentWillMount() {
        events.on(devices.types.CHANGE_DISPLAY_ATTRIBUTES, this.showAttributesChanged)
        events.on(devices.types.UPDATED, this.rerender);
    }

    componentWillUnmount() {
        events.off(devices.types.CHANGE_DISPLAY_ATTRIBUTES, this.showAttributesChanged)
        events.off(devices.types.UPDATED, this.rerender);
    }

    async save(item) {
        try {
            await DevicesService.update(item);
            store.dispatch(app.actions.hideModal());
            store.dispatch(devices.actions.updated(item));
            Events.emit(devices.types.UPDATED, item);
            store.dispatch(app.actions.hideModal());
        } catch (ex) {
            AppService.showError(ex);
        }
    }


    showForm(e) {
        e.stopPropagation();
        store.dispatch(app.actions.showModal({
            name: 'device-form',
            icon: 'mdi mdi mdi-car',
            title: t('sharedEdit') + ' ' + t('deviceTitle')
        }, {item: this.props.device, onSave: this.save}))
    }

    showMenu(e) {
        e.stopPropagation();
        this.setState({
            showMenu: true
        });

    }

    showCommand(e) {
        e.stopPropagation();
        this.setState({showCommand: true})
    }

    hideCommand() {
        this.setState({showCommand: false});
    }

    renderCommandForm() {
        const {device, position} = this.props;
        return <CommandModal onHide={this.hideCommand} device={device} position={position}/>
    }

    renderDetails() {
        const {device, position} = this.props;
        return <React.Fragment>
            {this.state.showAttributes.map(attr => {
                const key = attr.key;

                if (key === 'lastUpdate') {
                    return <div className="attr" key={key}><strong className="attr-name">{t(attr.title)}:</strong> <span
                        className="attr-value">{device.getFormattedProperty(key)}</span></div>
                }

                if (device.hasProperty(key) && device[key] != undefined && device[key] != null && device.getFormattedProperty(key) != '') {

                    if (attr === 'lastUpdate') {
                        return <div className="attr" key={key}><strong className="attr-name">{t(attr.title)}:</strong>
                            <span className="attr-value"><Interval
                                delay="60000">{device.getFormattedProperty(key)}</Interval></span></div>
                    }
                    return <div className="attr" key={key}><strong className="attr-name">{t(attr.title)}:</strong> <span
                        className="attr-value">{device.getFormattedProperty(key)}</span></div>
                }
            })}
        </React.Fragment>
    }

    render() {
        const {device, position} = this.props;
        return (
            <React.Fragment>
                {this.state.showCommand ? this.renderCommandForm() : null}
                <div className={'device updated ' + device.status + (device.selected ? ' selected ' : '')}>
                    <label className="custom-chk float-left">
                        <input type="checkbox" checked={!device.hidden} onChange={() => this.showChanged(device)}
                               onClick={(e) => e.stopPropagation()}/>
                        <span className="checkmark" onClick={(e) => e.stopPropagation()}></span>
                        &nbsp;
                    </label>
                    <div className="content">
                        <div className="info">
                            <span className="name" title={device.name}>{device.name}</span>
                            <span className="details">
                                {this.renderDetails()}
                            </span>
                        </div>
                    { App.App.user.id === customReadOnlyUser ? null :
                        <div onClick={e => e.stopPropagation()}
                             style={{float: 'right'}}
                        >
                            <DropdownButton
                                drop='right'
                                title={<i className="mdi mdi-dots-vertical"
                                          style={{color: "#000"}}
                                ></i>}
                                onToggle={(toggled) => {
                                    if (toggled) {
                                        document.body.classList.add('device-menu-toggled')
                                    } else {
                                        document.body.classList.remove('device-menu-toggled')
                                    }
                                }}
                            >
                                {App.App.userHasPermission('devices') ? <Dropdown.Item onClick={(e) => {
                                    this.showForm(e)
                                }}>
                                    <i className="mdi mdi-pencil"></i> {t('sharedEdit')}
                                </Dropdown.Item> : null}
                                <Dropdown.Item onClick={(e) => {
                                    this.showCommand(e)
                                }}>
                                    <i className="mdi mdi-apple-keyboard-command"></i> {t('commandTitle')}
                                </Dropdown.Item>

                                {position && position.hasAttribute('out1') ?
                                    <Dropdown.Item onClick={async (e) => {
                                        const command = new Command()
                                        command.description = "New"
                                        command.type = "custom"
                                        command.attributes = {
                                            data: "setdigout " + (position.attributes['out1'] ? "0" : "1")
                                        }
                                        command.deviceId = position.deviceId
                                        command.id = 0
                                        command.textChannel = null

                                        const response = await CommandsService.send(command)

                                        if (response.status === 202) {
                                            App.App.showToast(t('commandQueued'), '', {
                                                type: 'success',
                                            })
                                        } else {
                                            App.App.showToast(t('commandSent'), '', {
                                                type: 'success',
                                            })
                                        }
                                    }}>
                                        <i className={"mdi mdi-shield-key " +
                                            (position.hasAttribute('out1') ? (() => {
                                                    if (position.getAttribute('out1')) {
                                                        return 'text-success';
                                                    } else {
                                                        return 'text-danger'
                                                    }
                                                })()
                                                : '')}/> {
                                        t(position.attributes['out1'] ? 'unlock' : 'lock')
                                    }
                                    </Dropdown.Item> : null
                                }
                                <Dropdown.Item onClick={(e) => {
                                    events.emit("deviceExternalReport", device, 'summary')
                                }}>
                                    <i className="mdi mdi-table-row"></i> {t('dailySummary')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(e) => {
                                    events.emit("deviceExternalReport", device, 'route')
                                }}>
                                    <i className="mdi mdi-routes"></i> {t('dailyRoute')}
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    }
                        <div className="status">

                            <span className={'device-status online-status ' + device.status}
                                  title={t('deviceStatus' + device.status.ucFirst())}><i
                                className="mdi mdi-satellite-variant"></i></span>
                            <span
                                className={'device-status motion-status ' + (position && position.hasAttribute('motion') ? (position.getAttribute('motion') ? 'moving' : 'stopped') : 'unknown')}
                                title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i
                                className="mdi mdi-run-fast"></i></span>
                            {
                                position && position.hasAttribute('ignition') ?
                                    <span
                                        className={'device-status ignition-status ' + (position && position.hasAttribute('ignition') ? (position.getAttribute('ignition') ? 'on' : 'off') : 'unknown')}
                                        title={t('positionIgnition') + (position && position.getAttribute('ignition') ? ' ON' : ' OFF')}><i
                                        className="mdi mdi-engine-outline"></i></span>
                                    : null
                            }
                            <span
                                className="speed">{position && position.speed > 0 ? position.getFormattedProperty('speed') : ''}</span>
                        </div>
                        {/*App.App.userHasPermission('devices') ? <span className="edit" title={t('sharedEdit')} onClick={(e) => this.showMenu(e)}><i className="mdi mdi-dots-vertical"></i></span> : null*/}


                    </div>
                </div>
            </React.Fragment>
        )
    }
}

DeviceItem.propTypes = {
    id: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    active: PropTypes.bool
};

export default DeviceItem;