import constants from "../../config/constants";
import { Server } from "../../models";
import moment from "moment";
import PositionAttributes from "./PositionAttributes";
import { t } from "../Translator";
import { App } from "../../App";
import "moment-timezone";

const Ext = {};

export class AttributeFormatter {
  numberFormatterFactory(precision, suffix) {
    return function (value) {
      if (value !== undefined) {
        return Number(parseFloat(value).toFixed(precision)) + " " + suffix;
      }
      return null;
    };
  }

  coordinateFormatter(key, value) {
    return App.CoordinateFormatsConverter.formatValue(
      key,
      value,
      App.App.getPreference("coordinateFormat")
    );
  }

  speedFormatter(value) {
    return App.SpeedUnitsConverter.formatValue(
      value,
      App.App.getAttributePreference("speedUnit"),
      true
    );
  }

  speedConverter(value) {
    return App.SpeedUnitsConverter.convertValue(
      value,
      App.App.getAttributePreference("speed")
    );
  }

  courseFormatter(value) {
    var courseValues = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
    return courseValues[Math.floor(value / 45)];
  }

  distanceFormatter(value) {
    return App.DistanceUnitsConverter.formatValue(
      value,
      App.App.getAttributePreference("distanceUnit"),
      true
    );
  }

  distanceConverter(value) {
    return App.DistanceUnitsConverter.convertValue(
      value,
      App.App.getAttributePreference("distanceUnit")
    );
  }

  volumeFormatter(value) {
    return App.VolumeUnitsConverter.formatValue(
      value,
      App.App.getAttributePreference("volumeUnit"),
      true
    );
  }

  volumeConverter(value) {
    return App.VolumeUnitsConverter.convertValue(
      value,
      App.App.getAttributePreference("volumeUnit")
    );
  }

  hoursFormatter(value) {
    return App.HoursUnitsConverter.formatValue(value, "h", true);
  }

  hoursConverter(value) {
    return App.HoursUnitsConverter.convertValue(value, "h");
  }

  durationFormatter(value) {
    if (value === undefined || value === null) value = 0;
    return App.HoursUnitsConverter.formatValue(value, "h", true);
  }

  deviceIdFormatter(value) {
    if (value !== 0) {
      let store = App.Store.getState().devices.all_devices;
      let values = Object.values(store);
      let device = values.find((o) => o.id == value);

      if (!device) {
        store = App.Store.getState().devices.devices;
        values = Object.values(store);
        device = values.find((o) => o.id == value);
      }

      return device ? device.name : value;
    }
    return null;
  }

  groupIdFormatter(value) {
    if (value !== 0) {
      let store = App.Store.getState().groups.all_groups;
      let values = Object.values(store);
      if (values.length === 0) {
        store = App.Store.getState().groups.groups;
        values = Object.values(store);
      }
      const group = values.find((o) => o.id == value);
      return group ? group.name : "";
    }
    return null;
  }

  geofenceIdFormatter(value) {
    if (value !== 0) {
      let store = App.Store.getState().geofences.all_geofences;
      let values = Object.values(store);
      if (values.length === 0) {
        store = App.Store.getState().geofences.geofences;
        values = Object.values(store);
      }
      const geofence = values.find((o) => o.id == value);
      return geofence ? geofence.name : "";
    }
    return null;
  }

  calendarIdFormatter(value) {
    if (value !== 0) {
      let store = App.Store.getState().calendars.all_calendars;
      let values = Object.values(store);
      if (values.length === 0) {
        store = App.Store.getState().calendars.calendars;
        values = Object.values(store);
      }
      const calendar = values.find((o) => o.id == value);
      return calendar ? calendar.name : "";
    }
    return null;
  }

  driverUniqueIdFormatter(value) {
    if (value !== 0) {
      let store = App.Store.getState().drivers.all_drivers;
      let values = Object.values(store);
      if (values.length === 0) {
        store = App.Store.getState().drivers.drivers;
        values = Object.values(store);
      }
      const driver = values.find((o) => o.uniqueId == value);
      return driver ? driver.name : value;
    }
    return null;
  }

  maintenanceIdFormatter(value) {
    if (value !== 0) {
      const maintenance = App.Store.getState().maintenances.maintenances[value];
      return maintenance ? maintenance.name : "";
    }
    return null;
  }

  lastUpdateFormatter(value) {
    var seconds, interval;
    value = moment(value).valueOf();
    if (value) {
      seconds = Math.floor((new Date() - value) / 1000);
      if (seconds < 0) {
        seconds = 0;
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " " + t("sharedDays");
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " " + t("sharedHours");
      }
      return Math.floor(seconds / 60) + " " + t("sharedMinutes");
    }
    return null;
  }

  commandTypeFormatter(value) {
    var name = t("command" + value.charAt(0).toUpperCase() + value.slice(1));
    return name ? name : value;
  }

  timeFormatter(value) {
    let timeZone = App.App.user.attributes["timezone"];

    if (timeZone && timeZone.trim().length !== 0) {
      console.log("using timezone", timeZone);
      try {
        if (Server.twelveHourFormat) {
            console.log("old date", value)
          const a = moment(value)
            .tz(timeZone, false)
            .format(constants.dateTimeFormat12);
          console.log("new date", a)
          return a;
        } else {
            console.log("old date", value)
          const a = moment(value)
            .tz(timeZone, false)
            .format(constants.dateTimeFormat24);
            console.log("new date", a)
          return a;
        }
      } catch (e) {
        console.log("Timezone Error", e);
        if (Server.twelveHourFormat) {
          return moment(value).format(constants.dateTimeFormat12);
        } else {
          return moment(value).format(constants.dateTimeFormat24);
        }
      }
    } else {
      if (Server.twelveHourFormat) {
        return moment(value).format(constants.dateTimeFormat12);
      } else {
        return moment(value).format(constants.dateTimeFormat24);
      }
    }
  }

  defaultFormatter(value) {
    if (typeof value === "number") {
      return Number(value.toFixed(constants.numberPrecision));
    } else if (typeof value === "boolean") {
      return value;
    } else if (value instanceof Date) {
      if (Server.twelveHourFormat) {
        return moment(value).format(constants.dateTimeFormat12);
      } else {
        return moment(value).format(constants.dateTimeFormat24);
      }
    }
    return value;
  }

  getFormatter(key) {
    switch (key) {
      case "latitude":
      case "longitude":
        return (value) => {
          return this.coordinateFormatter(key, value);
        };
      case "speed":
        return this.speedFormatter;
      case "course":
        return this.courseFormatter;
      case "accuracy":
      case "distance":
        return this.distanceFormatter;
      case "duration":
        return this.durationFormatter;
      case "deviceId":
        return this.deviceIdFormatter;
      case "groupId":
        return this.groupIdFormatter;
      case "geofenceId":
        return this.geofenceIdFormatter;
      case "maintenanceId":
        return this.maintenanceIdFormatter;
      case "calendarId":
        return this.calendarIdFormatter;
      case "lastUpdate":
        return this.lastUpdateFormatter;
      case "spentFuel":
        return this.volumeFormatter;
      case "consumption":
        return this.numberFormatterFactory(
          constants.numberPrecision,
          t("sharedLiterPer100Kilometer")
        );
      case "consumption-percent":
        return this.numberFormatterFactory(
          constants.numberPrecision,
          "% " + t("sharedLiterPer100Kilometer")
        );
      case "driverUniqueId":
        return this.driverUniqueIdFormatter;
      case "commandType":
        return this.commandTypeFormatter;
      case "time":
        return this.timeFormatter;
      default:
        return this.defaultFormatter;
    }
  }

  getConverter(key) {
    switch (key) {
      case "speed":
        return this.speedConverter;
      case "accuracy":
      case "distance":
        return this.distanceConverter;
      case "spentFuel":
        return this.volumeConverter;
      default:
        return (value) => {
          return value;
        };
    }
  }

  getAttributeFormatter(key) {
    var dataType = PositionAttributes.getAttributeDataType(key);

    switch (dataType) {
      case "distance":
        return this.distanceFormatter;
      case "speed":
        return this.speedFormatter;
      case "driverUniqueId":
        return this.driverUniqueIdFormatter;
      case "voltage":
        return this.numberFormatterFactory(constants.numberPrecision, "V");
      case "percentage":
        return this.numberFormatterFactory(constants.numberPrecision, "&#37;");
      case "temperature":
        return this.numberFormatterFactory(constants.numberPrecision, "&deg;C");
      case "volume":
        return this.volumeFormatter;
      case "hours":
        return this.hoursFormatter;
      case "consumption":
        return this.numberFormatterFactory(
          constants.numberPrecision,
          t("sharedLiterPerHourAbbreviation")
        );
      default:
        return this.defaultFormatter;
    }
  }

  getAttributeConverter(key) {
    const dataType = PositionAttributes.getAttributeDataType(key);

    switch (dataType) {
      case "distance":
        return this.distanceConverter;
      case "speed":
        return this.speedConverter;
      case "volume":
        return this.volumeConverter;
      case "hours":
        return this.hoursConverter;
      default:
        return function (value) {
          return value;
        };
    }
  }

  renderAttribute(value, attribute) {
    if (attribute && attribute.dataType === "speed") {
      return App.SpeedUnitsConverter.formatValue(
        value,
        App.App.getAttributePreference("speedUnit", "kn"),
        true
      );
    } else if (attribute && attribute.dataType === "distance") {
      return App.DistanceUnitsConverter.formatValue(
        value,
        App.App.getAttributePreference("distanceUnit", "km"),
        true
      );
    } else if (attribute && attribute.dataType === "hours") {
      return App.HoursUnitsConverter.formatValue(value, "h", true);
    } else {
      return value;
    }
  }
}

export default new AttributeFormatter();
