import { Button, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React from 'react';
import { Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { App } from "../../../App";
import constants from '../../../config/constants';
import { User } from '../../../models';
import { AppService, SessionService } from '../../../services';
import { store } from '../../../store';
import { deepClone, isEqual } from '../../../utils/functions';
import { t } from '../../../utils/Translator';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import CustomNumberField from "../../Controls/CustomNumberField";
import CustomSelect from '../../Controls/CustomSelect';
import AttributesPage from '../Attributes';

class AccountPreferencesPage extends React.Component {
    constructor(props) {
        super(props);

        const mapOptions = [];
        Object.keys(constants.mapTypes[constants.selectedMapType].mapServices).map(i => {
            const c = constants.mapTypes[constants.selectedMapType].mapServices[i];
            if (c.enabled) {
                mapOptions.push({label: t(c.name), value: i, config: c});
            }

        });

        const coordinateFormats = [];
        const coordinate_formats = store.getState().coordinate_formats.coordinate_formats;
        Object.values(coordinate_formats).map(o => {
            coordinateFormats.push({label: t(o.name), value: o.key})
        });

        const user = new User().deserialize(deepClone(props.user))

        if (user.attributes === undefined)
            user.attributes = {}

        this.state = {
            attributesPage: false,
            sending: false,
            mapOptions: mapOptions,
            coordinateFormats: coordinateFormats,
            changed: false,
            user: user
        };


        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getMapState = this.getMapState.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
    }

    handleClose(modal) {
        this.setState({[modal]: false})
    }

    async handleSubmit(data, {setSubmitting}) {
        setSubmitting(true);
        try {
            const {user} = this.state;
            user.deserialize(data);
            await SessionService.updateUser(user);
            this.props.onHide();
        } catch (ex) {
            AppService.showError(ex);
        } finally {
            setSubmitting(false);
        }

    }

    openAttributesPage() {
        this.setState({attributesPage: true})
    }

    onAttributesCancel() {
        const clone = new User().deserialize(deepClone(this.props.user));
        const user = this.state.user;
        user.attributes = clone.attributes;
        this.setState({
            ...this.state,
            user
        })
        this.handleClose('attributesPage')
    }

    onAttributesClosed() {
        this.setState({changed: !isEqual(this.state.user.attributes, this.props.user.attributes)})
        this.handleClose('attributesPage')
    }


    getMapState(setFieldValue) {
        const state = store.getState().app.mapstate;
        setFieldValue('zoom', state.zoom)
        setFieldValue('latitude', state.latitude)
        setFieldValue('longitude', state.longitude)
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({user: new User().deserialize(deepClone(newProps.user))})
    }

    render() {
        const {sending, coordinateFormats, mapOptions, user} = this.state;


        const schema = yup.object({
            //phone: yup.string(),
            //mapLayer: yup.string(),
            latitude: yup.number(),
            longitude: yup.number(),
            zoom: yup.number().min(1),
            //twelveHourFormat: yup.bool(),
            //coordinateFormat: yup.string(),
            //poiLayer: yup.string(),
        });
        return (
            <React.Fragment>
                {this.state.attributesPage ?
                    <AttributesPage item={user} type="userAttributes" onHide={() => this.onAttributesClosed()}
                                    onSave={() => this.onAttributesClosed()}
                                    onCancel={() => this.onAttributesCancel()}/>
                    : null}

                <div className="container-wrapper">
                    <Formik
                        validationSchema={schema}
                        onSubmit={this.handleSubmit}
                        disabled={sending}
                        initialValues={{
                            phone: user.phone,
                            mapLayer: user.map,
                            latitude: user.latitude,
                            longitude: user.longitude,
                            zoom: user.zoom,
                            twelveHourFormat: user.twelveHourFormat,
                            coordinateFormat: user.coordinateFormat,
                            poiLayer: user.poiLayer,
                            attributes: user.attributes !== undefined ? user.attributes : {}
                        }}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              dirty,
                              isSubmitting,
                              errors,
                              isValid,
                              setFieldValue
                          }) => (

                            <Form onSubmit={handleSubmit} noValidate
                                  className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-settings"></i> {t('sharedPreferences')}</h3>


                                            <Row>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("positionLatitude")}</FormLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="latitude"
                                                        value={values.latitude}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.latitude}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("positionLongitude")}</FormLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="longitude"
                                                        value={values.longitude}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.longitude}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("serverZoom")}</FormLabel>
                                                    <InputGroup>
                                                        <FormControl
                                                            type="number"
                                                            name="zoom"
                                                            min="1"
                                                            className="d-inline-block"
                                                            value={values.zoom}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.zoom}
                                                        />
                                                        <InputGroup.Append>
                                                            <IconButton
                                                                style={{
                                                                    border: "none",
                                                                    outline: "none"
                                                                }}
                                                                color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0"
                                                                onClick={() => this.getMapState(setFieldValue)}
                                                                title={t("sharedGetMapState")}
                                                            >
                                                                <i className="mdi mdi-map-marker"></i>
                                                            </IconButton>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup className="col-12 col-md-6">
                                                    <FormLabel>{t("settingsCoordinateFormat")}</FormLabel>
                                                    <CustomSelect options={coordinateFormats} onChange={setFieldValue}
                                                                  defaultValue={values.coordinateFormat}
                                                                  name="coordinateFormat"/>

                                                </FormGroup>
                                                <FormGroup className="col-12 col-md-6">
                                                    <FormLabel>{t("mapPoiLayer")}</FormLabel>
                                                    <FormControl
                                                        type="string"
                                                        name="poiLayer"
                                                        value={values.poiLayer}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.poiLayer}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row>


                                            <FormGroup className="col-12 col-md-6">
                                                <FormLabel>{t("currency")}</FormLabel>
                                                <CustomSelect options={currencies} onChange={
                                                    (n, v) => {
                                                    const user = this.state.user
                                                    user.attributes["currency"] = v
                                                    this.setState({user: user, changed: true})
                                                }
                                                }
                                              defaultValue={values.attributes["currency"]||"EUR"} name="currency"/>
                                            </FormGroup>
                                            
                                            <FormGroup className="col-12 col-md-6">
                                                <CustomCheckbox name="twelveHourFormat" value="twelveHourFormat"
                                                                checked={values.twelveHourFormat}
                                                                onChange={handleChange}
                                                                label={t('settingsTwelveHourFormat')}/>
                                            </FormGroup>

                                            </Row>
                                            {user.attributes["settings_owner_notifications"] ?
                                                <FormGroup>
                                                    <CustomCheckbox name="notifyUsers" value="notifyUsers"
                                                                    checked={values.attributes['expiration_notification_enabled'] || false}
                                                                    onChange={
                                                                        event => {
                                                                            const user = this.state.user
                                                                            user.attributes["expiration_notification_enabled"] = event.target.checked
                                                                            this.setState({user: user, changed: true})
                                                                            handleChange(event)
                                                                        }
                                                                    }
                                                                    label={t('notifyUsersDeviceExpiration')}/>
                                                </FormGroup>
                                                : null
                                            }
                                            {user.attributes["settings_owner_notifications"] ?
                                                <FormGroup>
                                                    <TextField
                                                        className="w-100"
                                                        id="outlined-textarea"
                                                        InputProps={{style: {fontSize: 14}}}
                                                        InputLabelProps={{style: {fontSize: 14}}}
                                                        label={t("Contact Info")}
                                                        placeholder={t("Enter contact information where your clients can reach you")}
                                                        value={values.attributes['owner_contact_info']}
                                                        onChange={
                                                            event => {
                                                                const user = this.state.user
                                                                user.attributes["owner_contact_info"] = event.target.value
                                                                this.setState({user: user, changed: true})
                                                                handleChange(event)
                                                            }
                                                        }
                                                        multiline
                                                    />
                                                </FormGroup>
                                                : null
                                            }
                                            <FormGroup style={{zIndex: 1}}>
                                                <FormLabel>{t("mapLayer")}</FormLabel>
                                                <CustomSelect options={mapOptions} onChange={setFieldValue}
                                                              defaultValue={values.mapLayer} name="map"/>


                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("settingsDistanceUnit")}</FormLabel>
                                                <FormControl
                                                    as="select"
                                                    name="value"
                                                    value={values.attributes["distanceUnit"]}
                                                    onChange={event => {
                                                        this.state.user.attributes["distanceUnit"] = event.target.value
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }
                                                    }
                                                >
                                                    <option value=""></option>
                                                    {Object.values(store.getState().distance_units.distance_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                        return <option key={o.key}
                                                                       value={o.key}>{t(o.key)}</option>
                                                    })}
                                                </FormControl>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("settingsSpeedUnit")}</FormLabel>
                                                <FormControl
                                                    as="select"
                                                    name="value"
                                                    value={values.attributes["speedUnit"]}
                                                    onChange={event => {
                                                        this.state.user.attributes["speedUnit"] = event.target.value
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {Object.values(store.getState().speed_units.speed_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                        return <option key={o.key}
                                                                       value={o.key}>{t(o.key)}</option>
                                                    })}
                                                </FormControl>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("sharedTimezone")}</FormLabel>
                                                <FormControl
                                                    as="select"
                                                    name="timezone"
                                                    defaultValue={values.attributes["timezone"]}
                                                    onChange={event => {
                                                        this.state.user.attributes["timezone"] = event.target.value
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }
                                                    }>
                                                    <option value={""}></option>
                                                    {Object.values(store.getState().timezones.timezones).sort((a, b) => a.key > b.key ? 1 : -1).map(o => {
                                                        return <option key={o.key}
                                                                       value={o.key}>{t(o.key)}</option>
                                                    })}
                                                </FormControl>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("settingsVolumeUnit")}</FormLabel>
                                                <FormControl
                                                    as="select"
                                                    name="value"
                                                    value={values.attributes["volumeUnit"]}
                                                    onChange={event => {
                                                        this.state.user.attributes["volumeUnit"] = event.target.value
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }}
                                                >
                                                    <option value=""></option>
                                                    {Object.values(store.getState().volume_units.volume_units).sort((a, b) => (a.label > b.label ? 1 : -1)).map(o => {
                                                        return <option key={o.key}
                                                                       value={o.key}>{t(o.key)}</option>
                                                    })}
                                                </FormControl>
                                            </FormGroup>
                                            {App.App.user.administrator ?
                                                <FormGroup>
                                                    <FormLabel>{t("attributeWebLiveRouteLength")}</FormLabel>
                                                    <CustomNumberField
                                                        name="value"
                                                        value={values.attributes["web.liveRouteLength"]}
                                                        type="number"
                                                        onChange={event => {
                                                            if (event.target.value > 0)
                                                                this.state.user.attributes["web.liveRouteLength"] = event.target.value
                                                            else
                                                                delete this.state.user.attributes["web.liveRouteLength"]
                                                            handleChange(event)
                                                            this.setState({changed: true})
                                                        }}
                                                    />
                                                </FormGroup> : null}

                                            <FormGroup>
                                                <FormLabel>{t("attributeWebMaxZoom")}</FormLabel>
                                                <CustomNumberField
                                                    name="maxZoom"
                                                    value={values.attributes["web.maxZoom"]}
                                                    type="number"
                                                    onChange={event => {
                                                        if (event.target.value > 0)
                                                            this.state.user.attributes["web.maxZoom"] = event.target.value
                                                        else
                                                            delete this.state.user.attributes["web.maxZoom"]
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("attributeWebSelectZoom")}</FormLabel>
                                                <CustomNumberField
                                                    name="selectZoom"
                                                    value={values.attributes["web.selectZoom"]}
                                                    type="number"
                                                    onChange={event => {
                                                        if (event.target.value > 0)
                                                            this.state.user.attributes["web.selectZoom"] = event.target.value
                                                        else
                                                            delete this.state.user.attributes["web.selectZoom"]
                                                        handleChange(event)
                                                        this.setState({changed: true})
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {
                                                    this.state.user.administrator ?
                                                        <Button variant="primary" className="align-self-stretch"
                                                                onClick={() => this.openAttributesPage()}>
                                                            <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                        </Button>
                                                        : null
                                                }
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2"
                                                    onClick={() => this.props.onHide()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" className="btn-blue"
                                                    disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )}
                    </Formik>

                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.session.user
})


export default connect(mapStateToProps, null)(AccountPreferencesPage);

const currencies = [
    {label: "EUR", value: "EUR"},
    { label: "USD", value: "USD" }
]

const currencies2 = [
    {
      "iso4217": "AFN",
    },
    { "iso4217": "ALL",
    }   ,
    {
      "iso4217": "DZD",
    },
    {
      "iso4217": "AOA"
    },
    {
      "iso4217": "ARS",
    },
    {
      "iso4217": "AMD",
    },
    {
      "iso4217": "AWG",
    },
    {
      "iso4217": "AUD",
    },
    {
      "iso4217": "AZN",
    },
    {
      "iso4217": "BSD",
    },
    {
      "iso4217": "BDT",
    },
    {
      "iso4217": "BBD",
    },
    {
      "iso4217": "BZD",
    },
    {
      "iso4217": "BMD",
    },
    {
      "iso4217": "BOB",
    },
    {
      "name": "Bosnian Convertible Marka",
      "iso4217": "BAM",
      "isoNumeric": "977",
      "symbol": "KM",
      "subunit": "Fening",
      "subunitToUnit": null,
      "prefix": "KM",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Marka"
    },
    {
      "name": "Botswana Pula",
      "iso4217": "BWP",
      "isoNumeric": "072",
      "symbol": "P",
      "subunit": "Thebe",
      "subunitToUnit": null,
      "prefix": "P",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pula"
    },
    {
      "name": "Brazilian Real",
      "iso4217": "BRL",
      "isoNumeric": "986",
      "symbol": "$",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "R$ ",
      "suffix": "",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Real"
    },
    {
      "name": "British Pound",
      "iso4217": "GBP",
      "isoNumeric": "826",
      "symbol": "£",
      "subunit": "Penny",
      "subunitToUnit": null,
      "prefix": "£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pound"
    },
    {
      "name": "Brunei Dollar",
      "iso4217": "BND",
      "isoNumeric": "096",
      "symbol": "$",
      "subunit": "Sen",
      "subunitToUnit": null,
      "prefix": "B$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Bulgarian Lev",
      "iso4217": "BGN",
      "isoNumeric": "975",
      "symbol": "лв.",
      "subunit": "Stotinka",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " лв.",
      "decimalMark": ".",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Lev"
    },
    {
      "name": "Burundian Franc",
      "iso4217": "BIF",
      "isoNumeric": "108",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Cambodian Riel",
      "iso4217": "KHR",
      "isoNumeric": "116",
      "symbol": "៛",
      "subunit": "Sen",
      "subunitToUnit": null,
      "prefix": "៛ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Riel"
    },
    {
      "name": "Canadian Dollar",
      "iso4217": "CAD",
      "isoNumeric": "124",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "C$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Cape Verdean Escudo",
      "iso4217": "CVE",
      "isoNumeric": "132",
      "symbol": "Esc",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Escudo"
    },
    {
      "name": "Cayman Islands Dollar",
      "iso4217": "KYD",
      "isoNumeric": "136",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "CI£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Central African Franc",
      "iso4217": "XAF",
      "isoNumeric": "950",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "CFP Franc",
      "iso4217": "XPF",
      "isoNumeric": "953",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Chilean Peso",
      "iso4217": "CLP",
      "isoNumeric": "152",
      "symbol": "$",
      "subunit": "Peso",
      "subunitToUnit": null,
      "prefix": "CLP $",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ".",
      "decimalPlaces": 0,
      "shortName": "Peso"
    },
    {
      "name": "Chinese Yuan",
      "iso4217": "CNY",
      "isoNumeric": "156",
      "symbol": "¥",
      "subunit": "Fen",
      "subunitToUnit": null,
      "prefix": "¥",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Yuan"
    },
    {
      "name": "Colombian Peso",
      "iso4217": "COP",
      "isoNumeric": "170",
      "symbol": "$",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "COP $",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ".",
      "decimalPlaces": 0,
      "shortName": "Peso"
    },
    {
      "name": "Comorian Franc",
      "iso4217": "KMF",
      "isoNumeric": "174",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Congolese franc",
      "iso4217": "CDF",
      "isoNumeric": "976",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "franc"
    },
    {
      "name": "Costa Rican Colón",
      "iso4217": "CRC",
      "isoNumeric": "188",
      "symbol": "₡",
      "subunit": "Céntimo",
      "subunitToUnit": null,
      "prefix": "₡",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Colón"
    },
    {
      "name": "Croatian Kuna",
      "iso4217": "HRK",
      "isoNumeric": "191",
      "symbol": "kn",
      "subunit": "Lipa",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": "kn",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Kuna"
    },
    {
      "name": "Czech Koruna",
      "iso4217": "CZK",
      "isoNumeric": "203",
      "symbol": "Kč",
      "subunit": "Haléř",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " Kč",
      "decimalMark": "",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Koruna"
    },
    {
      "name": "Danish Krone",
      "iso4217": "DKK",
      "isoNumeric": "208",
      "symbol": "kr",
      "subunit": "Øre",
      "subunitToUnit": null,
      "prefix": "DKK ",
      "suffix": "",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Krone"
    },
    {
      "name": "Djiboutian Franc",
      "iso4217": "DJF",
      "isoNumeric": "262",
      "symbol": "Fdj",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": "Fdj",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Dominican Peso",
      "iso4217": "DOP",
      "isoNumeric": "214",
      "symbol": "RD$",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "RD$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Peso"
    },
    {
      "name": "Dutch Guilder",
      "iso4217": "ANG",
      "isoNumeric": "532",
      "symbol": "ƒ",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "ƒ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Guilder"
    },
    {
      "name": "East Caribbean Dollar",
      "iso4217": "XCD",
      "isoNumeric": "951",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "EC£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Egyptian Pound",
      "iso4217": "EGP",
      "isoNumeric": "818",
      "symbol": "ج.م",
      "subunit": "Piastre",
      "subunitToUnit": null,
      "prefix": "E£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pound"
    },
    {
      "name": "Emirati Dirham",
      "iso4217": "AED",
      "isoNumeric": "784",
      "symbol": "د.إ",
      "subunit": "Fils",
      "subunitToUnit": null,
      "prefix": "AED ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dirham"
    },
    {
      "name": "Ethiopian Birr",
      "iso4217": "ETB",
      "isoNumeric": "230",
      "symbol": "ብር",
      "subunit": "Santim",
      "subunitToUnit": null,
      "prefix": "Br",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Birr"
    },
    {
      "name": "Euro",
      "iso4217": "EUR",
      "isoNumeric": "978",
      "symbol": "€",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " €",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Euro"
    },
    {
      "name": "Falkland Islands Pound",
      "iso4217": "FKP",
      "isoNumeric": "238",
      "symbol": "FK£",
      "subunit": "Penny",
      "subunitToUnit": null,
      "prefix": "FK£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pound"
    },
    {
      "name": "Fijian Dollar",
      "iso4217": "FJD",
      "isoNumeric": "242",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "FJ£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Gambian Dalasi",
      "iso4217": "GMD",
      "isoNumeric": "270",
      "symbol": "D",
      "subunit": "Butut",
      "subunitToUnit": null,
      "prefix": "GMD",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dalasi"
    },
    {
      "name": "Georgian Lari",
      "iso4217": "GEL",
      "isoNumeric": "981",
      "symbol": "ლ",
      "subunit": "Tetri",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " ლ",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Lari"
    },
    {
      "name": "Gibraltar Pound",
      "iso4217": "GIP",
      "isoNumeric": "292",
      "symbol": "£",
      "subunit": "Penny",
      "subunitToUnit": null,
      "prefix": "£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pound"
    },
    {
      "name": "Guatemalan Quetzal",
      "iso4217": "GTQ",
      "isoNumeric": "320",
      "symbol": "Q",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "Q",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Quetzal"
    },
    {
      "name": "Guinean Franc",
      "iso4217": "GNF",
      "isoNumeric": "324",
      "symbol": "Fr",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "Fr",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Guyanese Dollar",
      "iso4217": "GYD",
      "isoNumeric": "328",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "G$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Haitian Gourde",
      "iso4217": "HTG",
      "isoNumeric": "332",
      "symbol": "G",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "G",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Gourde"
    },
    {
      "name": "Honduran Lempira",
      "iso4217": "HNL",
      "isoNumeric": "340",
      "symbol": "L",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "L",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Lempira"
    },
    {
      "name": "Hong Kong Dollar",
      "iso4217": "HKD",
      "isoNumeric": "344",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "HK$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Hungarian Forint",
      "iso4217": "HUF",
      "isoNumeric": "348",
      "symbol": "Ft",
      "subunit": "Fillér",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " Ft",
      "decimalMark": ".",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Forint"
    },
    {
      "name": "Icelandic Krona",
      "iso4217": "ISK",
      "isoNumeric": "352",
      "symbol": "ISK",
      "subunit": "Eyrir",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " ISK",
      "decimalMark": "",
      "thousandsSeparator": ".",
      "decimalPlaces": 0,
      "shortName": "Krona"
    },
    {
      "name": "Indian Rupee",
      "iso4217": "INR",
      "isoNumeric": "356",
      "symbol": "₹",
      "subunit": "Paisa",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " ₹",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rupee"
    },
    {
      "name": "Israeli New Shekel",
      "iso4217": "ILS",
      "isoNumeric": "376",
      "symbol": "₪",
      "subunit": "Agora",
      "subunitToUnit": null,
      "prefix": "₪",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": "",
      "decimalPlaces": 2,
      "shortName": "Shekel"
    },
    {
      "name": "Jamaican Dollar",
      "iso4217": "JMD",
      "isoNumeric": "388",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "J$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Japanese Yen",
      "iso4217": "JPY",
      "isoNumeric": "392",
      "symbol": "¥",
      "subunit": null,
      "subunitToUnit": null,
      "prefix": "¥",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Yen"
    },
    {
      "name": "Kazakhstani Tenge",
      "iso4217": "KZT",
      "isoNumeric": "398",
      "symbol": "₸",
      "subunit": "Tiyn",
      "subunitToUnit": null,
      "prefix": "₸",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Tenge"
    },
    {
      "name": "Kenyan Shilling",
      "iso4217": "KES",
      "isoNumeric": "404",
      "symbol": "KSh",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "KSh ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Shilling"
    },
    {
      "name": "Kyrgyzstani Som",
      "iso4217": "KGS",
      "isoNumeric": "417",
      "symbol": "лв",
      "subunit": "Tyiyn",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " лв",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Som"
    },
    {
      "name": "Lao Kip",
      "iso4217": "LAK",
      "isoNumeric": "418",
      "symbol": "₭",
      "subunit": "Att",
      "subunitToUnit": null,
      "prefix": "₭",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Kip"
    },
    {
      "name": "Lebanese Pound",
      "iso4217": "LBP",
      "isoNumeric": "422",
      "symbol": "LL",
      "subunit": "Piastre",
      "subunitToUnit": null,
      "prefix": "LL ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Pound"
    },
    {
      "name": "Lesotho loti",
      "iso4217": "LSL",
      "isoNumeric": "426",
      "symbol": "L",
      "subunit": "Sente",
      "subunitToUnit": null,
      "prefix": "L",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "loti"
    },
    {
      "name": "Liberian Dollar",
      "iso4217": "LRD",
      "isoNumeric": "430",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "LD£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Macanese Pataca",
      "iso4217": "MOP",
      "isoNumeric": "446",
      "symbol": "MOP",
      "subunit": "Avo",
      "subunitToUnit": null,
      "prefix": "MOP$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Pataca"
    },
    {
      "name": "Macedonian Denar",
      "iso4217": "MKD",
      "isoNumeric": "807",
      "symbol": "ден",
      "subunit": "Deni",
      "subunitToUnit": null,
      "prefix": "ден",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Denar"
    },
    {
      "name": "Malagasy Ariary",
      "iso4217": "MGA",
      "isoNumeric": "969",
      "symbol": "Ar",
      "subunit": "Iraimbilanja",
      "subunitToUnit": null,
      "prefix": "Ar",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Ariary"
    },
    {
      "name": "Malawian Kwacha",
      "iso4217": "MWK",
      "isoNumeric": "454",
      "symbol": "MK",
      "subunit": "Tambala",
      "subunitToUnit": null,
      "prefix": "MK",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Kwacha"
    },
    {
      "name": "Malaysian Ringgit",
      "iso4217": "MYR",
      "isoNumeric": "458",
      "symbol": "RM",
      "subunit": "Sen",
      "subunitToUnit": null,
      "prefix": "RM ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Ringgit"
    },
    {
      "name": "Maldivian Rufiyaa",
      "iso4217": "MVR",
      "isoNumeric": "462",
      "symbol": "Rf",
      "subunit": "Laari",
      "subunitToUnit": null,
      "prefix": "Rf",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rufiyaa"
    },
    {
      "name": "Mauritanian Ouguiya",
      "iso4217": "MRO",
      "isoNumeric": "478",
      "symbol": "UM",
      "subunit": "Khoums",
      "subunitToUnit": null,
      "prefix": "UM",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Ouguiya"
    },
    {
      "name": "Mauritian Rupee",
      "iso4217": "MUR",
      "isoNumeric": "480",
      "symbol": "Ɍs",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "Ɍs",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rupee"
    },
    {
      "name": "Mexican Peso",
      "iso4217": "MXN",
      "isoNumeric": "484",
      "symbol": "$",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "MXN$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Peso"
    },
    {
      "name": "Moldovan Leu",
      "iso4217": "MDL",
      "isoNumeric": "498",
      "symbol": "MDL",
      "subunit": "Ban",
      "subunitToUnit": null,
      "prefix": "MDL",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Leu"
    },
    {
      "name": "Mongolian Tughrik",
      "iso4217": "MNT",
      "isoNumeric": "496",
      "symbol": "₮",
      "subunit": "Möngö",
      "subunitToUnit": null,
      "prefix": "₮",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Tughrik"
    },
    {
      "name": "Moroccan Dirham",
      "iso4217": "MAD",
      "isoNumeric": "504",
      "symbol": "DH",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": "DH",
      "decimalMark": ",",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Dirham"
    },
    {
      "name": "Mozambican Metical",
      "iso4217": "MZN",
      "isoNumeric": "943",
      "symbol": "MT",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "MT",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Metical"
    },
    {
      "name": "Myanmar Kyat",
      "iso4217": "MMK",
      "isoNumeric": "104",
      "symbol": "K",
      "subunit": "Pya",
      "subunitToUnit": null,
      "prefix": "K",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Kyat"
    },
    {
      "name": "Namibian Dollar",
      "iso4217": "NAD",
      "isoNumeric": "516",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "N£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Nepalese Rupee",
      "iso4217": "NPR",
      "isoNumeric": "524",
      "symbol": "रु",
      "subunit": "Paisa",
      "subunitToUnit": null,
      "prefix": "Rs ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rupee"
    },
    {
      "name": "New Zealand Dollar",
      "iso4217": "NZD",
      "isoNumeric": "554",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "NZ$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Nicaraguan Córdoba",
      "iso4217": "NIO",
      "isoNumeric": "558",
      "symbol": "C$",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "C$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Córdoba"
    },
    {
      "name": "Nigerian Naira",
      "iso4217": "NGN",
      "isoNumeric": "566",
      "symbol": "₦",
      "subunit": "Kobo",
      "subunitToUnit": null,
      "prefix": "₦",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Naira"
    },
    {
      "name": "Norwegian Krone",
      "iso4217": "NOK",
      "isoNumeric": "578",
      "symbol": "kr",
      "subunit": "Øre",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " kr",
      "decimalMark": ",",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Krone"
    },
    {
      "name": "Pakistani Rupee",
      "iso4217": "PKR",
      "isoNumeric": "586",
      "symbol": "Rs",
      "subunit": "Paisa",
      "subunitToUnit": null,
      "prefix": "Rs ",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Rupee"
    },
    {
      "name": "Panamanian Balboa",
      "iso4217": "PAB",
      "isoNumeric": "590",
      "symbol": "B/.",
      "subunit": "Centésimo",
      "subunitToUnit": null,
      "prefix": "B/.",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Balboa"
    },
    {
      "name": "Papua New Guinean Kina",
      "iso4217": "PGK",
      "isoNumeric": "598",
      "symbol": "K",
      "subunit": "Toea",
      "subunitToUnit": null,
      "prefix": "K",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Kina"
    },
    {
      "name": "Paraguayan Guarani",
      "iso4217": "PYG",
      "isoNumeric": "600",
      "symbol": "₲",
      "subunit": "Céntimo",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " ₲",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Guarani"
    },
    {
      "name": "Peruvian Sol",
      "iso4217": "PEN",
      "isoNumeric": "604",
      "symbol": "S/.",
      "subunit": "Céntimo",
      "subunitToUnit": null,
      "prefix": "S/.",
      "suffix": "",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Sol"
    },
    {
      "name": "Philippine Peso",
      "iso4217": "PHP",
      "isoNumeric": "608",
      "symbol": "₱",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "₱",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Peso"
    },
    {
      "name": "Polish Zloty",
      "iso4217": "PLN",
      "isoNumeric": "985",
      "symbol": "zł",
      "subunit": "Grosz",
      "subunitToUnit": null,
      "prefix": "zł",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Zloty"
    },
    {
      "name": "Qatari Riyal",
      "iso4217": "QAR",
      "isoNumeric": "634",
      "symbol": "ر.ق",
      "subunit": "Dirham",
      "subunitToUnit": null,
      "prefix": "QR",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Riyal"
    },
    {
      "name": "Romanian Leu",
      "iso4217": "RON",
      "isoNumeric": "946",
      "symbol": "lei",
      "subunit": "Ban",
      "subunitToUnit": null,
      "prefix": "lei ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Leu"
    },
    {
      "name": "Russian Rubel",
      "iso4217": "RUB",
      "isoNumeric": "643",
      "symbol": "руб",
      "subunit": "Kopek",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": "руб",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rubel"
    },
    {
      "name": "Rwandan Franc",
      "iso4217": "RWF",
      "isoNumeric": "646",
      "symbol": "FRw",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "RWF ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Salvadoran Colón",
      "iso4217": "SVC",
      "isoNumeric": "222",
      "symbol": "SVC",
      "subunit": "Centavo",
      "subunitToUnit": null,
      "prefix": "SVC",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Colón"
    },
    {
      "name": "Samoan Tala",
      "iso4217": "WST",
      "isoNumeric": "882",
      "symbol": "WST",
      "subunit": "Sene",
      "subunitToUnit": null,
      "prefix": "WST",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Tala"
    },
    {
      "name": "Saudi Arabian Riyal",
      "iso4217": "SAR",
      "isoNumeric": "682",
      "symbol": "ر.س",
      "subunit": "Hallallah",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " ﷼",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Riyal"
    },
    {
      "name": "Serbian Diner",
      "iso4217": "RSD",
      "isoNumeric": "941",
      "symbol": "Дин",
      "subunit": "Para",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " din",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Diner"
    },
    {
      "name": "Seychellois Rupee",
      "iso4217": "SCR",
      "isoNumeric": "690",
      "symbol": "Ɍs",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "Ɍs",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rupee"
    },
    {
      "name": "Sierra Leonean Leone",
      "iso4217": "SLL",
      "isoNumeric": "694",
      "symbol": "Le",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "Le",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Leone"
    },
    {
      "name": "Singapore Dollar",
      "iso4217": "SGD",
      "isoNumeric": "702",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "SG$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Solomon Islands Dollar",
      "iso4217": "SBD",
      "isoNumeric": "090",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "SI£",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Somali Shilling",
      "iso4217": "SOS",
      "isoNumeric": "706",
      "symbol": "SOS",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "SOS",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Shilling"
    },
    {
      "name": "South African Rand",
      "iso4217": "ZAR",
      "isoNumeric": "710",
      "symbol": "R",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "R",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rand"
    },
    {
      "name": "South Korean Won",
      "iso4217": "KRW",
      "isoNumeric": "410",
      "symbol": "₩",
      "subunit": null,
      "subunitToUnit": null,
      "prefix": "₩ ",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Won"
    },
    {
      "name": "Sri Lankan Rupee",
      "iso4217": "LKR",
      "isoNumeric": "144",
      "symbol": "Rs",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "Rs ",
      "suffix": "",
      "decimalMark": ",",
      "thousandsSeparator": ".",
      "decimalPlaces": 2,
      "shortName": "Rupee"
    },
    {
      "name": "Surinamese Dollar",
      "iso4217": "SRD",
      "isoNumeric": "968",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Swazi Lilangeni",
      "iso4217": "SZL",
      "isoNumeric": "748",
      "symbol": "SZL",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "SZL",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Lilangeni"
    },
    {
      "name": "Swedish Krona",
      "iso4217": "SEK",
      "isoNumeric": "752",
      "symbol": "kr",
      "subunit": "Öre",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " kr",
      "decimalMark": ",",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Krona"
    },
    {
      "name": "Swiss Franc",
      "iso4217": "CHF",
      "isoNumeric": "756",
      "symbol": "CHF",
      "subunit": "Rappen",
      "subunitToUnit": null,
      "prefix": "CHF ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": "‘",
      "decimalPlaces": 2,
      "shortName": "Franc"
    },
    {
      "name": "Taiwan New Dollar",
      "iso4217": "TWD",
      "isoNumeric": "901",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": " NT$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Tajikistani Somoni",
      "iso4217": "TJS",
      "isoNumeric": "972",
      "symbol": "TJS",
      "subunit": "Diram",
      "subunitToUnit": null,
      "prefix": "TJS",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Somoni"
    },
    {
      "name": "Tanzanian Shilling",
      "iso4217": "TZS",
      "isoNumeric": "834",
      "symbol": "TSh",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "TSh ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Shilling"
    },
    {
      "name": "Thai Baht",
      "iso4217": "THB",
      "isoNumeric": "764",
      "symbol": "฿",
      "subunit": "Satang",
      "subunitToUnit": null,
      "prefix": "฿",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Baht"
    },
    {
      "name": "Tongan Paanga",
      "iso4217": "TOP",
      "isoNumeric": "776",
      "symbol": "T$",
      "subunit": "Seniti",
      "subunitToUnit": null,
      "prefix": "T$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Paanga"
    },
    {
      "name": "Trinidad and Tobago Dollar",
      "iso4217": "TTD",
      "isoNumeric": "780",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "TT$",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Turkish Lira",
      "iso4217": "TRY",
      "isoNumeric": "949",
      "symbol": "TL",
      "subunit": "kuruş",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " TL",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Lira"
    },
    {
      "name": "Ugandan Shilling",
      "iso4217": "UGX",
      "isoNumeric": "800",
      "symbol": "USh",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "USh ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Shilling"
    },
    {
      "name": "Ukrainian Hryvnia",
      "iso4217": "UAH",
      "isoNumeric": "980",
      "symbol": "₴",
      "subunit": "Kopiyka",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": " UAH",
      "decimalMark": ",",
      "thousandsSeparator": " ",
      "decimalPlaces": 2,
      "shortName": "Hryvnia"
    },
    {
      "name": "Uruguayan Peso",
      "iso4217": "UYU",
      "isoNumeric": "858",
      "symbol": "$",
      "subunit": "Centésimo",
      "subunitToUnit": null,
      "prefix": "U$",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ".",
      "decimalPlaces": 0,
      "shortName": "Peso"
    },
    {
      "name": "US Dollar",
      "iso4217": "USD",
      "isoNumeric": "840",
      "symbol": "$",
      "subunit": "Cent",
      "subunitToUnit": null,
      "prefix": "US$ ",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Dollar"
    },
    {
      "name": "Uzbekistani Som",
      "iso4217": "UZS",
      "isoNumeric": "860",
      "symbol": "лв",
      "subunit": "Tiyin",
      "subunitToUnit": null,
      "prefix": "лв",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Som"
    },
    {
      "name": "Vanuatu vatu",
      "iso4217": "VUV",
      "isoNumeric": "548",
      "symbol": "VT",
      "subunit": null,
      "subunitToUnit": null,
      "prefix": "VT",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "vatu"
    },
    {
      "name": "Vietnamese Đồng",
      "iso4217": "VND",
      "isoNumeric": "704",
      "symbol": "₫",
      "subunit": "Hào",
      "subunitToUnit": null,
      "prefix": "",
      "suffix": "₫",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Đồng"
    },
    {
      "name": "West African Franc",
      "iso4217": "XOF",
      "isoNumeric": "952",
      "symbol": "XOF",
      "subunit": "Centime",
      "subunitToUnit": null,
      "prefix": "XOF",
      "suffix": "",
      "decimalMark": "",
      "thousandsSeparator": ",",
      "decimalPlaces": 0,
      "shortName": "Franc"
    },
    {
      "name": "Yemeni Rial",
      "iso4217": "YER",
      "isoNumeric": "886",
      "symbol": "﷼",
      "subunit": "Fils",
      "subunitToUnit": null,
      "prefix": "﷼",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Rial"
    },
    {
      "name": "Zambian Kwacha",
      "iso4217": "ZMW",
      "isoNumeric": null,
      "symbol": "ZMW",
      "subunit": null,
      "subunitToUnit": null,
      "prefix": "ZMW",
      "suffix": "",
      "decimalMark": ".",
      "thousandsSeparator": ",",
      "decimalPlaces": 2,
      "shortName": "Kwacha"
    }
  ]