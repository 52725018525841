import { Button } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import Datetime from "react-datetime";
import * as yup from 'yup';
import { Expense } from '../../../models';
import { store } from "../../../store";
import { deepClone } from '../../../utils/functions';
import { t } from '../../../utils/Translator';
import CustomNumberField from "../../Controls/CustomNumberField";
import CustomSelect from "../../Controls/CustomSelect";

export default class ExpensesForm extends React.Component {
    constructor(props) {
        super(props);


        const allDevices = store.getState().devices.devices;
        const deviceOptions = [];
        Object.values(allDevices).map(d => {
            if (!d.disabled) deviceOptions.push({ value: d.id, label: d.name });
        })
        deviceOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        let item = props.item

        if(item === undefined && props.maintenance) {
            item = {
                type: "maintenance"
            }
        }

        console.log("ITEM", item)

        let i = new Expense().deserialize(deepClone(item))
        if (!i._data.date)
            i._data.date = new Date()

        console.log("CURRENT",i)

        this.state = {
            attributesPage: false,
            item: i,
            mode: props.mode,
            sending: false,
            changed: false,
            selectedDevice: i.deviceId || null,
            allDevices: allDevices,
            deviceOptions: deviceOptions,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.changeSelectedDevices = this.changeSelectedDevices.bind(this);
    }

    save(values) {
        const item = this.state.item;
        item.distance = 0
        item.deviceId = this.state.selectedDevice || 0
        console.log("item", item);
        if(!this.saved) {
        this.props.onSave(item);
        this.saved = true
        }
        
    }

    cancel() {
        this.props.onHide();
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Expense().deserialize(deepClone(newProps.item)) })
    }

    changeSelectedDevices(name, value) {
        this.setState({
            selectedDevice: value,
            changed: true
        })
    }

    render() {
        const { sending, item } = this.state;
        console.log('renderitem', item)

        const schema = yup.object({
            description: yup.string().required(),
            date: yup.date().required(),
            type: yup.string().required(),
            amount: yup.number().required()
        });

        const types = [
            {
                label: t("sharedMaintenance"),
                value: "maintenance"
            },
            {
                label: t("sharedRepair"),
                value: "repair"
            },
            {
                label: t("positionFuel"),
                value: "fuel"
            },
            {
                label: t("sharedToll"),
                value: "toll"
            },
            {
                label: t("insurance"),
                value: "insurance"
            },
            {
                label: t("reportCustom"),
                value: "other"
            }
        ]


        let type
        if(item.type) {
            type = Object.values(types).find(i => i.value == item.type)
        if (type == undefined || type == null)
            type = "other"
        else
            type = type.value
        }

        console.log('type',type)

        return <React.Fragment>
            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        description: item.description,
                        date: item.date,
                        amount: item.amount,
                        type: type,
                        deviceOptions: this.state.deviceOptions,
                        selectedDevice: this.state.selectedDevice
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                noValidate
                                className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-account-key"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedExpenses')}
                                            </h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedDescription")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    defaultValue={values.description}
                                                    onChange={event => {
                                                        let m = new Expense().deserialize(deepClone(this.state.item._data))
                                                        m._data.description = event.target.value
                                                        this.setState({
                                                            item: m,
                                                            changed: true
                                                        })
                                                        handleChange(event)
                                                    }}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("date")}</FormLabel>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="YYYY-MM-DD"
                                                    defaultValue={values.date}
                                                    onChange={(date) => {
                                                        let m = new Expense().deserialize(deepClone(this.state.item._data))
                                                        m._data.date = new Date(date.valueOf())
                                                        this.setState({
                                                            item: m,
                                                            changed: true
                                                        })
                                                    }}> </Datetime>
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("expenseCost")}</FormLabel>
                                                <CustomNumberField
                                                    name="amount"
                                                    value={values.amount}
                                                    type="number"
                                                    onChange={event => {
                                                        let m = new Expense().deserialize(deepClone(this.state.item._data))
                                                        m._data.amount = event.target.value
                                                        this.setState({
                                                            item: m,
                                                            changed: true
                                                        })
                                                        handleChange(event)
                                                    }}
                                                />
                                            </FormGroup>
                                            {this.props.maintenance ? null :
                                            <FormGroup>
                                                <FormLabel>{t("sharedType")}</FormLabel>
                                                <CustomSelect
                                                    options={Object.values(types)}
                                                    isClearable
                                                    name="type"
                                                    defaultValue={values.type}
                                                    onChange={(n, v) => {
                                                        let m = new Expense().deserialize(deepClone(this.state.item._data))
                                                        m._data.type = v
                                                        this.setState({
                                                            item: m,
                                                            changed: true
                                                        })
                                                        handleChange(v)
                                                    }
                                                    }
                                                />
                                                {type !== 'other' ? null
                                                :
                                                    <FormControl
                                                        type="text"
                                                        name="other"
                                                        defaultValue={item.type == "other"?"":item.type}
                                                        onChange={event => {
                                                            let m = new Expense().deserialize(deepClone(this.state.item._data))
                                                            m._data.type = event.target.value
                                                            this.setState({
                                                                item: m,
                                                                changed: true
                                                            })
                                                            handleChange(event)
                                                        }}
                                                        isInvalid={!!errors.name}
                                                    />
                                                }
                                            </FormGroup>
                                             }
                                            <FormGroup>
                                                <FormLabel>{t("sharedDevice")}</FormLabel>
                                                <CustomSelect className="custom-select-margin-50"
                                                    options={values.deviceOptions}
                                                    defaultValue={values.selectedDevice}
                                                    onChange={this.changeSelectedDevices} />
                                            </FormGroup>
                                        </div>
                                   
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <Button variant="contained" size="large"
                                                className="btn-red mr-2" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained" size="large" className="btn-blue"
                                                onClick={() => this.save(values)}
                                                disabled={
                                                    !this.state.changed &&
                                                    (!dirty || !isValid)
                                                }
                                            >
                                                <i className="mdi mdi-content-save"></i>{" "}
                                                {t("sharedSave")}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}