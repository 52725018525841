import { push } from 'connected-react-router';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { DefaultLayout } from '../layouts/default';
import { ExternalLayout } from '../layouts/external';
//pages imports
import LoginPage from '../pages/auth/login';
import { NewPasswordPage } from "../pages/auth/newpassword";
import { PasswordResetPage } from "../pages/auth/passwordreset";
import { RegisterPage } from '../pages/auth/register';
import { VerifyCodePage } from "../pages/auth/verifycode";
import { Error404Page } from '../pages/error/404';
import HomePage from '../pages/home';

export class GenericRoute extends Route {
    componentWillMount() {
    }
}

export class ExternalRoute extends Route {

    render() {
        const user = this.props.store.getState().session.user;
        if (user) {
            const next = localStorage.getItem('AFTER_LOGIN_PAGE') || '/';
            this.props.store.dispatch(push(next));
            return null
        }
        return (
            <ExternalLayout>
                <Route {...this.props} />
            </ExternalLayout>
        )

    }
}

export class AuthenticatedRoute extends Route {
    render() {
        const user = this.props.store.getState().session.user;
        localStorage.removeItem('AFTER_LOGIN_PAGE');

        if (!user) {
            localStorage.setItem('AFTER_LOGIN_PAGE', this.props.location.pathname);
            this.props.store.dispatch(push('/login', {
                prev: this.props.location.pathname
            }));
            return null
        }
        return (
            <DefaultLayout>
                <Route {...this.props} />
            </DefaultLayout>
        )
    }
}


export class AuthenticadedOrGuestRoute extends Route {

}

export const WebRoutes = (store) => {
    return (
        <GenericRoute>
            <Switch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
                wrapperComponent={false}
            >
                <AuthenticatedRoute exact path='/' component={HomePage} store={store} />
                <ExternalRoute exact path='/login' component={LoginPage} store={store} />
                <ExternalRoute exact path='/register' component={RegisterPage} store={store} />
                <ExternalRoute exact path='/passwordreset' component={PasswordResetPage} store={store} />
                <ExternalRoute exact path='/newpassword' component={NewPasswordPage} store={store} />
                <ExternalRoute exact path='/verifycode' component={VerifyCodePage} store={store} />
                <GenericRoute component={Error404Page} />
            </Switch>
        </GenericRoute>
    )
};
