import React from 'react';
import { ListGroup } from 'react-bootstrap';
import {Button} from '@mui/material'
import { t } from '../../../utils/Translator';
import { store } from '../../../store';
import { User, Geofence, Device } from '../../../models';
import { Server } from '../../../models/Server';
import FormModal from './form';
import Page from '../../Page';
import { AppService } from '../../../services';
import EmptyList from '../../EmptyList';
import { App } from '../../../App';
import DeleteIcon from "@mui/icons-material/Delete";
import {IconButton} from "@mui/material";

class AttributesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formModal: false,
            key: null,
        };
        this.definitions = {};
    }

    getAttributeValue(key, value) {
        switch (key) {
            case 'volumeUnit':
                return t(store.getState().volume_units.volume_units[value].fullName);
            case 'distanceUnit':
                return t(store.getState().distance_units.distance_units[value].name);
            case 'speedUnit':
                return t(store.getState().speed_units.speed_units[value].name);
            default:
                if (this.definitions[key] && this.definitions[key].valueType === 'color') {
                    return "<div class='attr-color' style='background-color:" + value + "'>" + value + "</div>";
                }
                return App.AttributeFormatter.renderAttribute(value, this.definitions[key] || key);
        }


        /*switch (key) {
            case 'volumeUnit':
                return t(store.getState().volume_units.volume_units[value].fullName);
            default:
                return value.toString();
        }*/
    }

    getAttributeName(key, definitions) {
        switch (key) {
            case 'distanceUnit':
            case 'volumeUnit':
            case 'hourUnit':
            case 'coordinateFormat':
            case 'speedUnit':
                return t('settings' + key.ucFirst());
            case 'timezone':
                return t('shared' + key.ucFirst());
        }
        if (definitions[key]) {
            return t(definitions[key].name)
        }
        return key;
    }

    async deleteAttribute(key) {
        if (await AppService.showDeleteConfirm()) {
            delete this.props.item.attributes[key];
            this.forceUpdate();
        }
    }

    editAttribute() {

    }

    showForm(id) {

        this.setState({ formModal: true, key: id, mode: id ? 'edit' : 'add' }, () => {
            this.forceUpdate();
        })


    }

    hideForm() {
        this.setState({ formModal: false })
    }

    render() {
        const { item, onSave, onCancel } = this.props;
        item.attributes = item.attributes || {};
        this.definitions = {};
        if (item instanceof User) {
            this.definitions = store.getState().user_attributes.user_attributes;
        } else if (item instanceof Server) {
            this.definitions = store.getState().common_user_attributes.common_user_attributes;
        } else if (item instanceof Geofence) {
            this.definitions = store.getState().geofence_attributes.geofence_attributes;
        } else if (item instanceof Device) {
            this.definitions = store.getState().device_attributes.device_attributes;
        }

        const attributes = [];
        Object.keys(item.attributes).map(k => {
            attributes.push({
                name: this.getAttributeName(k, this.definitions),
                value: this.getAttributeValue(k, item.attributes[k]),
                key: k,
            })
        })

        attributes.sort((a, b) => a.name > b.name ? 1 : -1)

        return (
            <React.Fragment>
                {this.state.formModal ? <FormModal mode={this.state.mode} show={true} {...this.props} attributeKey={this.state.key} attributes={item.attributes} definitions={this.definitions} onHide={() => this.hideForm()} /> : null}

                <Page onHide={this.props.onHide} title={<React.Fragment><h3 className="title"><i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}</h3></React.Fragment>}>
                    <div className="container-wrapper grow">
                        <div className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                            <div className="flex-grow-1 pt-3 overflow">
                                <div className="container">
                                    <h3 className="internal-title"><i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}</h3>
                                    {attributes.length > 0 ? <ListGroup >
                                        {attributes.map(a => {
                                            return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={a.key}>
                                                <div className="content" onClick={() => this.showForm(a.key)}>
                                                    <strong className="name">{a.name}</strong>
                                                    <span className="value" dangerouslySetInnerHTML={{ __html: a.value }}></span>
                                                </div>
                                                <div className="actions">
                                                    <IconButton style={{
                                                        border:"none",
                                                        outline:"none"
                                                    }} color="error" className="p-0 mt-2" onClick={() => {
                                                        this.deleteAttribute(a.key)
                                                    }}><DeleteIcon color="warn"  fontSize="small"></DeleteIcon></IconButton>
                                                </div>
                                            </ListGroup.Item>
                                        })}
                                    </ListGroup> : <EmptyList />}

                                </div>
                            </div>
                            <footer className="footer">
                                <div className="container d-flex">
                                    <div className="flex-grow-1 d-flex">
                                        <Button variant="primary" className="align-self-stretch" onClick={() => this.showForm()}>
                                            <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')}
                                        </Button>
                                    </div>
                                    <Button variant="contained" size="large" className="btn-red mr-2" onClick={onCancel}>
                                        <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                    </Button>
                                    <Button type="submit" variant="contained" size="large" className="btn-blue" onClick={onSave}>
                                        <i className="mdi mdi-check" ></i> {t('sharedSave')}
                                    </Button>
                                </div>
                            </footer>
                        </div>
                    </div>
                </Page>

            </React.Fragment>
        )

    }
}

export default AttributesPage;