
import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'description',
    type: 'string'
}, {
    name: 'date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD HH:mm:ss'
},{
    name: 'amount',
    type: 'float'
}, {
    name: 'type',
    type: 'string'
}, {
    name: 'deviceId',
    type: 'int'
}, {
    name: 'distance',
    type: 'int'
}

];


export class Expense extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}