import { store } from "../store";
import { t } from '../utils/Translator';
import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'type',
    type: 'string'
}, {
    name: 'start',
    type: 'float'
}, {
    name: 'period',
    type: 'float'
}, {
    name: 'attributes'
}];

export class Maintenance extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }

    static build(
        maintenanceItem,
        defaultName,
        maintenanceDateSelected,
        maintenancePeriodSelected,
        date,
        dateRepeatable,
        dateRepeatType,
        dateRepeatDayCount,
        start,
        period
    ) {
        console.log(
            'maintenance',
            maintenanceItem,
            'defaultName',
            defaultName,
            'maintenanceDateSelected',
            maintenanceDateSelected,
            'maintenancePeriodSelected',
            maintenancePeriodSelected,
            'date',
            date,
            'dateRepeatable',
            dateRepeatable,
            'dateRepeatType',
            dateRepeatType,
            'dateRepeatDayCount',
            dateRepeatDayCount,
            'start',
            start,
            'period',
            period
        )
        const maintenance = new Maintenance()
        maintenance.id = maintenanceItem.id
        const maintenanceName = maintenanceItem.name ?? t("Maintenance") + " " + defaultName
        let dateSelected = maintenanceDateSelected
        let periodSelected = maintenancePeriodSelected

        const maintenanceCurrentDate = date ? date : null
        const repeatable = dateRepeatable ? dateRepeatable : null
        const repeatType = dateRepeatType ? dateRepeatType : null
        let repeatDayCount = dateRepeatDayCount ? dateRepeatDayCount : null

        if (repeatType === '1')
            repeatDayCount = repeatDayCount * 30
        else if (repeatType === '2')
            repeatDayCount = repeatDayCount * 365

        const maintenanceStart = start ? start : null
        const maintenancePeriod = period ? period : null

        if (dateSelected && maintenanceCurrentDate === null)
            dateSelected = false

        if (periodSelected && maintenanceStart === null && maintenancePeriod === null)
            periodSelected = false

        const type = dateSelected && periodSelected ? "date, odometer" : dateSelected ? "date" : periodSelected ? "odometer" : null

        maintenance.type = type
        maintenance.name = maintenanceName
        maintenance.period = periodSelected ? maintenancePeriod : 0
        maintenance.start = periodSelected ? maintenanceStart : 0

        if(!maintenance.attributes) 
            maintenance.attributes = {}

        if (!dateSelected) {
            delete maintenance.attributes['date']
            delete maintenance.attributes['repeatable']
            delete maintenance.attributes['repeatType']
            delete maintenance.attributes['repeatDayCount']
        }
        else {
            maintenance.attributes['date'] = date
            maintenance.attributes['repeatable'] = repeatable
            maintenance.attributes['repeatDayCount'] = repeatDayCount

            if (repeatDayCount !== null) {
                maintenance.attributes['repeatDayCount'] = repeatDayCount
                maintenance.attributes['repeatType'] = repeatType
            }
        }

        if (!repeatable) {
            delete maintenance.attributes['repeatType']
            delete maintenance.attributes['repeatDayCount']
        }

        if (!periodSelected) {
            delete maintenance.attributes['maintenanceStart']
            delete maintenance.attributes['maintenancePeriod']
        }
        else {
            maintenance.attributes['maintenanceStart'] = maintenanceStart
            maintenance.attributes['maintenancePeriod'] = maintenancePeriod
        }

        return maintenance
    }
}