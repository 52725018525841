import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2196f3",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        secondary: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        error: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        warning: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        info: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        success: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        },
        background: {
            main: "#9e9e9e",
            "&:hover": {
                backgroundColor: "#9e9e9e",
            }
        }
    },
})

export default theme;