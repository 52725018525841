import React from "react";
import {Button} from "@mui/material"
import { t } from "../../../utils/Translator";
import Page from "../../Page";
import CalendarImportExport from "./CalendarImportExport"

class CalendarModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Page
          onHide={this.props.onHide}
          title={
            <React.Fragment>
              <h3 className="title">
                <i className="mdi mdi-playlist-play"></i>
                {t("sharedCalendar")}
              </h3>
            </React.Fragment>
          }
        >
          <div className="container-wrapper grow">
            <div className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
              <div className="flex-grow-1 pt-3 overflow">
                <div className="container">
                  <h3 className="internal-title">
                    <i className="mdi mdi-playlist-play"></i>
                    {t("sharedCalendar")}
                  </h3>
                  <CalendarImportExport></CalendarImportExport>
                </div>
              </div>
              <footer className="footer">
                <div className="container d-flex">
                  <Button variant="contained" size="large" className="btn-red"  onClick={this.props.onHide}>
                    <i className="mdi mdi-close"></i> {t("sharedCancel")}
                  </Button>
                </div>
              </footer>
            </div>
          </div>
        </Page>
      </React.Fragment>
    );
  }
}

export default CalendarModal;
