import images_config from '../config/device_images';
import constants from '../config/constants';

export default class DeviceImages {

    static getStartEndSvg(start) {
        //let i, info, svg, width, height, rotateTransform, scaleTransform, fill;
        const info = start ? images_config.startEndMarkers["marker_a"] : images_config.startEndMarkers["marker_b"]

        let svg = document.getElementById(info.key).contentDocument.cloneNode(true);

        if (!svg) {
            svg = DeviceImages.cloneDocument(info.svg);
        }

        svg.getElementById(info.scaleId).setAttribute('transform', 'scale(' + 0.37 + ') ');

        let width = parseFloat(svg.documentElement.getAttribute('width'));
        let height = parseFloat(svg.documentElement.getAttribute('height'));

        svg.documentElement.setAttribute('width', width);
        svg.documentElement.setAttribute('height', height);
        svg.documentElement.setAttribute('viewBox', '0 0 ' + width + ' ' + height);

        return svg;
    };

    static getStopIdleSvg(stop) {
        //let i, info, svg, width, height, rotateTransform, scaleTransform, fill;
        const info = stop ? images_config.stopIdleMarkers["marker_stop"] : images_config.stopIdleMarkers["marker_idle"]

        let svg = document.getElementById(info.key).contentDocument.cloneNode(true);

        if (!svg) {
            svg = DeviceImages.cloneDocument(info.svg);
        }

        if (stop) {
            svg.getElementById(info.scaleId).setAttribute('transform', 'scale(' + 0.90 + ') ');
        } else {
            svg.getElementById(info.scaleId).setAttribute('transform', 'scale(' + 0.95 + ') ');
        }

        let width = parseFloat(svg.documentElement.getAttribute('width'));
        let height = parseFloat(svg.documentElement.getAttribute('height'));

        svg.documentElement.setAttribute('width', width);
        svg.documentElement.setAttribute('height', height);
        svg.documentElement.setAttribute('viewBox', '0 0 ' + width + ' ' + height);

        return svg;
    };

    static getAlarmSvg(event) {
        let alarm = null

        if (event === 'hardBraking') alarm = "marker_braking"
        else if (event === 'hardAcceleration') alarm = "marker_acceleration"
        else if (event === 'hardCornering') alarm = "marker_cornering"
        else return null;

        const info = images_config.alarmMarkers[alarm]

        let svg = document.getElementById(info.key).contentDocument.cloneNode(true);

        if (!svg) {
            svg = DeviceImages.cloneDocument(info.svg);
        }

        // svg.getElementById(info.scaleId).setAttribute('transform', 'scale(' + 0.90 + ') ');

        let width = parseFloat(svg.documentElement.getAttribute('width'));
        let height = parseFloat(svg.documentElement.getAttribute('height'));

        svg.documentElement.setAttribute('width', width);
        svg.documentElement.setAttribute('height', height);
        svg.documentElement.setAttribute('viewBox', '0 0 ' + width + ' ' + height);

        return svg;
    };

    static svgReport = {}

    static getImageSvg(statusColor, iconColor, zoom, angle, category, report) {
        //let i, info, svg, width, height, rotateTransform, scaleTransform, fill;
        const info = report ? images_config.reportImages[category] || images_config.reportImages['default']
            : images_config.images[category] || images_config.images['default'];

        const key = statusColor + "" + iconColor + zoom + angle + category

        if (report) {
            if (DeviceImages.svgReport[key])
                return DeviceImages.svgReport[key]
        }


        let svg = document.getElementById(info.key).contentDocument.cloneNode(true);

        if (!svg) {
            svg = DeviceImages.cloneDocument(info.svg);
        }
        let scaleTransform = '';

        let width = parseFloat(svg.documentElement.getAttribute('width'));
        let height = parseFloat(svg.documentElement.getAttribute('height'));

        if (statusColor) {
            let statusFill = info.statusFillId;
            if (!Array.isArray(statusFill)) {
                statusFill = [statusFill];
            }
            statusFill.map(i => {
                const el = svg.getElementById(i);
                if (el) {
                    el.style.fill = statusColor;
                }
                return el
            })
        }

        if (iconColor) {
            let iconFill = info.iconFillId;
            if (iconFill) {
                if (!Array.isArray(iconFill)) {
                    iconFill = [iconFill];
                }
                iconFill.map(i => {
                    const el = svg.getElementById(i);
                    if (el) {
                        el.style.fill = iconColor;
                    }
                    return el
                })
            }
        }

        const rotateTransform = 'rotate(' + angle + ' ' + width / 2 + ' ' + height / 2 + ')';
        //console.log(info);
        svg.getElementById(info.rotateId).setAttribute('transform', rotateTransform);

        if (zoom) {
            width *= constants.device.scale.selected;
            height *= constants.device.scale.selected;
            scaleTransform = 'scale(' + constants.device.scale.selected + ') ';
        } else {
            width *= constants.device.scale.normal;
            height *= constants.device.scale.normal;
            scaleTransform = 'scale(' + constants.device.scale.normal + ') ';
        }

        if (info.scaleId !== info.rotateId) {
            svg.getElementById(info.scaleId).setAttribute('transform', scaleTransform);
        } else {
            svg.getElementById(info.scaleId).setAttribute('transform', scaleTransform + ' ' + rotateTransform);
        }

        svg.documentElement.setAttribute('width', width);
        svg.documentElement.setAttribute('height', height);
        svg.documentElement.setAttribute('viewBox', '0 0 ' + width + ' ' + height);

        if (report) {
            DeviceImages.svgReport[key] = svg
        }

        return svg;
    };

    static formatSrc(svg) {
        return 'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(new XMLSerializer().serializeToString(svg.documentElement));
    };

    static cloneDocument(svgDocument) {
        var newDocument, newNode;
        newDocument = svgDocument.implementation.createDocument(svgDocument.namespaceURI, null, null);
        newNode = newDocument.importNode(svgDocument.documentElement, true);
        newDocument.appendChild(newNode);
        return newDocument;
    };

    static getImageInfo(statusColor, iconColor, zoom, angle, category) {
        const svg = DeviceImages.getImageSvg(statusColor, iconColor, zoom, angle, category);
        const width = parseFloat(svg.documentElement.getAttribute('width'));
        const height = parseFloat(svg.documentElement.getAttribute('height'));
        return {
            svg: svg,
            width: width,
            height: height,
            statusFill: statusColor,
            iconColor: iconColor,
            angle: angle,
            category: category,
            src: DeviceImages.formatSrc(svg)
        }
    }

    static getReportImageInfo(statusColor, iconColor, zoom, angle, category) {
        const svg = DeviceImages.getImageSvg(undefined, iconColor, zoom, angle, category, true);
        const width = parseFloat(svg.documentElement.getAttribute('width'));
        const height = parseFloat(svg.documentElement.getAttribute('height'));
        return {
            svg: svg,
            width: width,
            height: height,
            iconColor: iconColor,
            angle: angle,
            category: category,
            src: DeviceImages.formatSrc(svg)
        }
    }

    static getStartEndInfo(start) {
        const svg = DeviceImages.getStartEndSvg(start);
        const width = parseFloat(svg.documentElement.getAttribute('width'));
        const height = parseFloat(svg.documentElement.getAttribute('height'));
        return {
            svg: svg,
            width: width,
            height: height,
            iconColor: undefined,
            angle: undefined,
            category: undefined,
            src: DeviceImages.formatSrc(svg)
        }
    }

    static getStopIdle(stop) {
        const svg = DeviceImages.getStopIdleSvg(stop);
        const width = parseFloat(svg.documentElement.getAttribute('width'));
        const height = parseFloat(svg.documentElement.getAttribute('height'));
        return {
            svg: svg,
            width: width,
            height: height,
            iconColor: undefined,
            angle: undefined,
            category: undefined,
            src: DeviceImages.formatSrc(svg)
        }
    }

    static getAlarm(event) {
        const svg = DeviceImages.getAlarmSvg(event);
        const width = parseFloat(svg.documentElement.getAttribute('width'));
        const height = parseFloat(svg.documentElement.getAttribute('height'));
        return {
            svg: svg,
            width: width,
            height: height,
            iconColor: undefined,
            angle: undefined,
            category: undefined,
            src: DeviceImages.formatSrc(svg)
        }
    }

    //we won't use openlayers for now
    /*static getImageIcon(color, zoom, angle, category) {
        const info = DeviceImages.getImageInfo(color, zoom, angle, category);

        const image = new Icon({
            imgSize: [info.width, info.height],
            src: info.src,
        });
        image.fill = info.color;
        image.zoom = info.zoom;
        image.angle = info.angle;
        image.category = info.category;

        return image;
    };*/
}