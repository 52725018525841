import React from 'react';
import Root from './Root';
import {Provider} from 'react-redux';
import {store} from './store';


export default class Main extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <Root/>
            </Provider>
        )
    }
}